
.main-background {
  height: 100%;
  min-width: 100%;
  background-color: var(--v-background-darken1);
}

.content-wrapper {
  height: 100%;
  min-width: 100%;
  padding-top: 32px;
  padding-bottom: 32px;
  padding-right: 32px;
  padding-left: 0px;
}
