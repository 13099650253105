<template>
  <div class="range-slider-wraper pt-10 pb-8 px-6">
    <v-range-slider
      class="custom-range-slider"
      color="primary"
      track-color="border"
      v-model="value"
      :step="step"
      :min="min"
      :max="max"
      thumb-size="28"
      thumb-label="always"
      @change="hadleChange"
    >
      <template v-slot:thumb-label="{ value }">
        <span class="text-body-3">{{ value }}€</span>
      </template>
    </v-range-slider>
  </div>
</template>
<script lang="ts" src="./sRangeSliderStepsRate.ts" />

<style scoped>
.range-slider-wraper {
  border: 1px solid var(--v-border-base);
  border-radius: 4px;
}

.custom-range-slider {
  margin-bottom: 0 !important;
}

.custom-range-slider >>> .v-input__slot {
  margin-bottom: 0 !important;
}

.custom-range-slider >>> .v-messages {
  display: none;
}
</style>
