<template>
  <div
    data-cy="experience-level-card"
    :class="[
      'd-flex',
      'cursor-pointer',
      'px-4',
      'py-2',
      { 'wrapper-border': isActive === false, 'wrapper-active-border': isActive === true }
    ]"
    style="background-color: transparent"
    @mouseover="isHovered = true"
    @mouseleave="isHovered = false"
    @click="handleClick"
  >
    <div class="mr-2" data-cy="experience-level-text">
      <span
        :class="[
          'text-body-1',
          {
            'text-body-1-bold primary--text text--base': isActive === true,
            'text--text text--base': isHovered === false && isActive === false,
            'primary--text text--base': isHovered === true && isActive === false
          }
        ]"
      >
        {{ seniorityLevelText }}
      </span>
    </div>

    <div class="d-flex align-center" data-cy="experience-level-stars">
      <div v-for="n in 1 + value" data-cy="experience-level-star-filled">
        <v-img
          v-if="isHovered === false && isActive === false"
          :width="20"
          src="/content/svgs/star/star-fill-grey.svg"
          data-cy="grey-filled-star"
        ></v-img>
        <v-img v-else :width="20" src="/content/svgs/star/star-fill-blue.svg" data-cy="blue-filled-star"></v-img>
      </div>

      <div v-for="n in 2 - value" data-cy="experience-level-star-empty">
        <v-img :width="20" src="/content/svgs/star/star-empty-grey-lighten1.svg"></v-img>
      </div>
    </div>
  </div>
</template>

<script lang="ts" src="./experienceLevelCard.ts"></script>

<style scoped>
.wrapper-border {
  border: 1px solid var(--v-border-base);
  border-radius: 4px;
}

.wrapper-active-border {
  border: 1px solid var(--v-primary-base);
  border-radius: 4px;
  position: relative;
}

.wrapper-active-border::before {
  content: '';
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border: 2px solid var(--v-primary-base);
  border-radius: 4px;
  pointer-events: none;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
