
.image-container {
  display: flex;
  justify-content: center;
  margin-top: -60px;
}

.image-container > * {
  z-index: 1; // Asegura que la imagen esté por encima del fondo gris
}
