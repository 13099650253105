#noContent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .text-no-content-title {
    width: 614px !important;
    text-align: center !important;
    color: #222226;
    font-size: 14px !important;
  }
  .text-no-content {
    width: 614px !important;
    text-align: justify !important;
    color: #606061;
  }
  .frame-nocontent {
    background-color: #f9f9f9;
    padding: 14px;
    border-radius: 50%;
    .img-nocontent {
      filter: brightness(0.47);
    }
  }
}
