
.custom-container {
  width: 100%;
  height: 100%;
  background: var(--v-background-darken1);
  box-shadow: 0px 1px 0px var(--v-border-darken1);
  border-right: 1px solid var(--v-border-darken1);
}

.custom-v-autocomplete {
  cursor: pointer;
}

.custom-v-autocomplete ::v-deep .v-input__slot {
  cursor: pointer !important;
  background: var(--v-background-darken1) !important;
  padding: 0 16px !important;
}

.custom-v-autocomplete ::v-deep .v-select__slot input {
  font-size: 16px !important;
  font-weight: 500 !important;
  color: var(--v-text-base) !important;
}

::v-deep .v-select__slot {
  white-space: nowrap;
}

.custom-v-autocomplete ::v-deep .v-text-field__details {
  display: none;
}

::v-deep .v-list-item--link:before {
  background-color: transparent;
}

::v-deep .v-list-item .v-list-item__title:hover {
  font-weight: 500;
}

::v-deep .v-list-item--active .v-list-item__title {
  color: var(--v-primary-base) !important;
  font-size: 16px !important;
  font-weight: 700 !important;
}

::v-deep .v-list-item .v-list-item__title {
  color: var(--v-text-base);
  font-size: 16px;
  font-weight: 400;
}
