#explore {
  border-radius: var(--border-radius, 4px);
  background: var(--White, #fff);
  min-width: 100%;
  min-height: 100%;

  #title-page {
    color: var(--Body, var(--Colors-Body, #222226));

    /* Heading/Heading (H3) */
    font-family: 'Manrope';
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }

  .btn-myteam {
    border-radius: var(--border-radius, 4px);
    border: 1px solid var(--Colors-Grey, #dbdbe0);
    height: 45px !important;
  }

  .v-badge__badge {
    width: 2em !important;
    transform: scale(3) !important;
  }
  .fadeIn {
    height: 50vh;
    animation: fadeIn 1s;
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .explore-header {
    position: sticky;
    top: 0;
    z-index: 200;
    background-color: var(--v-background-base);
    border-bottom: 1px solid transparent;
  }

  .explore-header.scrolled {
    border-bottom: 1px solid var(--v-border-base);
  }
}
