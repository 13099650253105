#stemdoer-profile {
  background-color: #f9f9f9;
  display: flex;
  flex-direction: row;
  color: '#222226';
  border-radius: 4px;

  #stemdoer-description {
    display: flex;
    flex-direction: column;
  }
  .candidate-name {
    color: #222226 !important;
  }
  .candidate-title {
    color: #222226 !important;
  }
}

#candidate-list-detail {
  .sections {
    border: 1px solid #ececef;
    border-radius: 4px;

    .sections-title {
      margin: 0px;
      color: #606061;
    }

    .sections-description {
      font-family: 'Manrope';
      font-style: normal;
      line-height: 21px;
      color: #222226;
      margin: 0px;
    }

    .text {
      color: #222226;
    }
  }
}
