#no-content-team {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  flex-direction: column;
  border-radius: 4px;
  border: 1px var(--v-text-lighten2) solid;
  font-size: 16px;

  .menu {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    margin: auto;
    color: var(--v-text-base) !important;
  }
}
