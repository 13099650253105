#checkout-chart {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 206px;

  .title-span {
    color: $blackStemdo;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    padding-bottom: 3px;
  }
}

.toolBoxTop {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid $lightGreyStemdo;

  .btnPDF {
    width: 19px;
    height: 19px;
    flex-shrink: 0;
    transition: 0.6s;
    margin-left: 6px;
    box-shadow: none;

    .iconPDF {
      transform: scale(0.9);
      transition: 0.3s;
    }
  }

  .btnPDF:hover .iconPDF {
    transform: scale(0.9);
    transition: 0.3s;
  }

  button:hover {
    transition: 0.8s;
  }

  button:active {
    transform: translate(0em, 0.7em);
  }
}
