
.main-box {
  border: 1px solid #dbdbe074;
  border-radius: 3px;
  width: 100%;
}

.theme--light.v-chip:not(.v-chip--active) {
  color: var(--v-text-lighten1) !important;
  background-color: var(--v-background-darken2) !important;
}

.header-content {
  color: var(--v-text-base);
  font-size: 20px;
  font-family: Manrope;
  font-weight: 800;
  word-wrap: break-word;
  width: fit-content;
}

.exp-container {
  width: 100%;
  margin-left: 0em;
}

.tooltip-bottom::before {
  border-right: solid 8px transparent;
  border-left: solid 8px transparent;
  transform: translateX(-50%);
  position: absolute;
  z-index: -21;
  content: '';
  bottom: 100%;
  left: 50%;
  height: 0;
  width: 0;
}

.tooltip-bottom.background-darken3::before {
  border-bottom: solid 6px var(--v-background-darken3) !important;
}

.verfication-mark {
  margin-top: -1.7em;
  margin-left: 0.7em;
}

.title-position {
  color: var(--v-text-base);
  font-size: 16px;
  font-family: Manrope;
  font-weight: 800;
  word-wrap: break-word;
  margin-left: 0% !important;
  margin-bottom: 0% !important;
  padding: 0%;
}

.position {
  color: var(--v-text-base);
  font-size: 16px;
  font-family: Manrope;
  font-weight: 800 !important;
  word-wrap: break-word;
}

.company {
  color: var(--v-text-base);
  font-size: 16px;
  font-family: Manrope;
  font-weight: 600;
  word-wrap: break-word;
}

.total-time {
  color: var(--v-text-lighten1);
  font-size: 14px;
  font-family: Manrope;
  font-weight: 400;
  word-wrap: break-word;
}

.date {
  color: var(--v-text-base);
  font-size: 16px;
  font-family: Manrope;
  font-weight: 400;
  word-wrap: break-word;
}

.verfication-mark {
  margin-top: -1.7em;
  margin-left: 0.7em;
}

::v-deep .v-timeline {
  padding-top: 8px !important;
}

.v-timeline:before {
  height: calc(100% - 155px);
  color: #ececef !important;
  top: 10px;
  margin-right: 0;
  margin-left: 0;
  left: 2px !important;
}

::v-deep .v-timeline-item__dot {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  height: 6px !important;
  width: 6px !important;
}

::v-deep .v-timeline-item__divider {
  height: fit-content !important;
  width: fit-content !important;
  color: #ececef !important;
  min-width: fit-content !important;
  max-width: fit-content !important;
}

::v-deep .v-timeline-item {
  display: flex !important;
  justify-content: left !important;
}

::v-deep .v-timeline-item__body {
  margin-left: 22px !important;
}
