<template>
  <v-container class="p-0 m-0" style="min-width: 100% !important">
    <v-row no-gutters class="d-flex justify-content-start flex-nowrap">
      <div class="slide-container">
        <v-slide-group show-arrows class="d-flex align-center">
          <v-slide-item v-for="tag in tags" :key="`${tag.getId()}`" class="text-center mr-2">
            <FilterTag :tag="tag" />
          </v-slide-item>
        </v-slide-group>
      </div>
      <div class="clear-button text--text text--lighten-1 font-weight-bold align-self-center ml-2" @click="handleClear">
        <div class="mr-auto" style="width: min-content">{{ $t('explore.filter.clearFilters') }}</div>
      </div>
    </v-row>
  </v-container>
</template>

<script lang="ts" src="./filterTagList.ts"></script>

<style scoped>
.slide-container {
  max-width: 89%;
}

.clear-button {
  cursor: pointer;

  &:hover {
    color: var(--var-text-base) !important;
  }
}
</style>
