
::v-deep .v-chip__content {
  color: #606061 !important;
  font-size: 14px !important;
  font-family: Manrope !important;
  font-weight: 400 !important;
  word-wrap: break-word !important;
  height: 31px !important;
  width: fit-content !important;
}
::v-deep .theme--light.v-chip:not(.v-chip--active) {
  background-color: #ececef !important;
}
