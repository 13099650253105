
.tooltip-right::before {
  content: ' ';
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -6px;
  border-width: 6px;
  border-style: solid;
  border-top: solid 6px transparent;
  border-bottom: solid 6px transparent;
  border-left: solid 6px transparent;
}

.tooltip-right.primary::before {
  border-right: solid 6px var(--v-background-darken3) !important;
}

.link-button {
  max-width: 22px !important;
  cursor: pointer;
  transition: transform 0.1s;
}
