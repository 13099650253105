// It's necessary to use !important because otherwise, the styles would be overridden by vuetify.min.css
.v-application {
  font-family: 'Manrope' !important;

  .app-font-family {
    font-family: 'Manrope' !important;
  }

  .text-h1 {
    @extend .app-font-family;
    font-size: 32px !important;
    font-weight: 600 !important;
  }

  .text-h2 {
    @extend .app-font-family;
    line-height: 1.5;
    font-size: 28px !important;
    font-weight: 600 !important;
  }

  .text-h2-bold {
    @extend .app-font-family;
    line-height: 1.5;
    font-size: 28px !important;
    font-weight: 800 !important;
  }

  .text-h3 {
    @extend .app-font-family;
    line-height: 1.5;
    font-size: 24px !important;
    font-weight: 600 !important;
  }

  .text-h3-bold {
    @extend .app-font-family;
    @extend .text-h3;
    font-weight: 800 !important;
  }

  .text-h4 {
    @extend .app-font-family;
    font-size: 20px !important;
    font-weight: 800 !important;
  }

  .text-h5 {
    @extend .app-font-family;
    font-size: 20px !important;
    font-weight: 600 !important;
  }

  .text-h6 {
    @extend .app-font-family;
    font-size: 20px !important;
    font-weight: 800 !important;
  }

  .text-subtitle-1 {
    @extend .app-font-family;
    font-size: 16px !important;
  }

  .text-subtitle-2 {
    @extend .app-font-family;
    font-size: 14px !important;
  }

  .text-subtitle-3 {
    @extend .app-font-family;
    font-size: 14px !important;
    font-weight: 400 !important;
  }

  .text-subtitle-4 {
    @extend .app-font-family;
    font-size: 14px !important;
    font-weight: 600 !important;
  }

  .text-subtitle-5 {
    @extend .app-font-family;
    font-size: 14px !important;
    font-weight: 800 !important;
  }

  .text-body-1 {
    @extend .app-font-family;
    font-size: 16px !important;
    font-weight: 400 !important;
  }

  .text-body-1-medium {
    @extend .text-body-1;
    font-weight: 600 !important;
  }

  .text-body-1-bold {
    @extend .text-body-1;
    font-weight: 800 !important;
  }

  .text-body-2 {
    @extend .app-font-family;
    font-size: 14px !important;
  }

  .text-body-2-medium {
    @extend .text-body-2;
    font-weight: 600 !important;
  }

  .text-body-2-bold {
    @extend .text-body-2;
    font-weight: 800 !important;
  }

  .text-body-3 {
    @extend .app-font-family;
    font-size: 12px !important;
    font-weight: 400 !important;
  }

  .text-body-3-medium {
    @extend .text-body-3;
    font-weight: 600 !important;
  }

  .text-button {
    @extend .app-font-family;
    font-size: 16px !important;
  }

  .text-body-4 {
    @extend .app-font-family;
    font-size: 10px !important;
    font-weight: 400 !important;
  }

  .text-body-4-medium {
    @extend .text-body-3;
    font-weight: 600 !important;
  }

  .text-body-4-bold {
    @extend .text-body-3;
    font-weight: 800 !important;
  }
}
