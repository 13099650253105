#stemdoerDetail {
  height: max-content;
  width: 100%;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;

  h5 {
    color: var(--v-text-base);
    font-size: 14px;
    font-family: Manrope;
    font-weight: 600;
    word-wrap: break-word;
  }

  .row {
    width: 100% !important;
  }

  a:hover {
    color: inherit;
  }

  .secondBox {
    border-radius: 3px;
    width: 100%;
  }

  .experienceBox {
    border: 1px solid #dbdbe074;
    margin: 2em;
    border-radius: 3px;
    width: 100%;
  }

  .educationBox {
    border: 1px solid #dbdbe074;
    margin: 2em;
    border-radius: 3px;
    width: 100%;
  }

  .description {
    color: var(--v-text-lighten1);
    font-size: 16px;
    font-family: Manrope;
    font-weight: 400;
    word-wrap: break-word;
    margin-left: -2px;
  }

  .btnPDF {
    width: 19px;
    height: 19px;
    flex-shrink: 0;
    transition: 0.6s;
    margin-left: 6px;
    box-shadow: none;

    .iconPDF {
      transform: scale(0.9);
      transition: 0.3s;
    }
  }

  .btnPDF:hover .iconPDF {
    transform: scale(0.9);
    transition: 0.3s;
  }

  .pdf {
    color: var(--v-text-base);
    font-size: 16px;
    font-family: Manrope;
    font-weight: 800;
    word-wrap: break-word;
  }

  .toolBoxTop {
    transform: scale(1.1);

    span {
      margin-left: -0.5em;
    }
  }

  .badge-style {
    width: fit-content;
    height: 32px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 9px;
    padding-bottom: 7px;
    background: white;
    border-radius: 4px;
    border: 1px var(--v-border-darken1) solid;
    justify-content: center;
    align-items: center;
    gap: 10px;
    display: inline-flex;
    z-index: 2;
    white-space: nowrap;
  }

  .badge-text {
    color: var(--v-text-lighten1);
    word-wrap: break-word;
  }
}
