
@import './teamRequests.scss';

::v-deep .v-text-field__details {
  display: none !important;
}

.v-tooltip__content {
  background-color: var(--v-background-darken3) !important;
  opacity: 1;
  height: 33px;
  color: var(--v-btnPrimaryText-base) !important;
  border-radius: 4px;
}

.tooltip-right::before {
  content: ' ';
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -6px;
  border-width: 6px;
  border-style: solid;
  border-top: solid 6px transparent;
  border-bottom: solid 6px transparent;
  border-left: solid 6px transparent;
}

.tooltip-right.primary::before {
  border-right: solid 6px var(--v-background-darken3) !important;
}

.link-button {
  cursor: pointer;
  transition: transform 0.1s;
  filter: brightness(0) saturate(100%) invert(37%) sepia(0%) saturate(0%) hue-rotate(180deg) brightness(91%) contrast(89%);
}

.link-button:active {
  transform: scale(0.9) translateY(2px);
}

.popUp {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  margin: 16px 16px 0px 0px;
  width: fit-content;
  min-width: 35%;
}
