#btn-my-team {
  .btn-myteam {
    border-radius: var(--border-radius, 4px);
    border: 1px solid var(--Colors-Grey, #dbdbe0);
    height: 45px !important;
  }

  .alert-box {
    width: 298px !important;
    height: 90px !important;
    max-width: 298px !important;
    max-height: 90px !important;
    min-width: 298px !important;
    min-height: 90px !important;
    background: white;
    box-shadow: 0px 2px 8px #dbdbe0;
    border-radius: 4px;
    position: fixed;
    top: 120px;
    right: 64px;
    z-index: 4;
  }

  .alert-text {
    color: #222226;
    font-size: 14px;
    font-family: Manrope;
    font-weight: 400;
    word-wrap: break-word;
    text-align: start;
    width: 185px !important;
  }

  .btn-exit {
    position: absolute;
    top: 8px;
    right: 8px;
    color: #222226;
    font-size: 14px;
    font-family: Manrope;
    font-weight: 400;
    cursor: pointer;
    z-index: 4;
    transform: scale(1.3);
  }
}
