@import '_variables';
@import 'bootstrap-variables';
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
@import url('https://cdn.jsdelivr.net/npm/@mdi/font@6.x/css/materialdesignicons.min.css');

/* ==============================================================
Bootstrap tweaks
===============================================================*/

.notiClass {
  border: red 2px solid;
}

.cookieFlag {
  border: 1px solid $lightGreyStemdo;
  width: max-content;
  background-color: #ffffff;
  display: inline-block;
  position: fixed;
  bottom: 25px;
  right: 20px;
  border-radius: 10px;
  width: 25em;
  height: max-content;
  z-index: 1;
  .close {
    display: flex;
    position: absolute;
    top: -5px;
    right: 5px;
    padding: 0.5em;
    svg {
      margin-top: 0em;
    }
  }
  p {
    text-align: justify;
    font-family: 'Manrope', Verdana, Geneva, Tahoma, sans-serif;
    font-size: 14px;
    padding: 0.5em;
  }
  .row {
    // justify-content: center;
    margin: 0% !important;
    align-items: center;
    width: inherit !important;
  }

  .cookiesvg {
    border-radius: 10px;
    svg {
      transition: 0.5s;
      margin-top: -0.5em;
    }
    svg:hover {
      cursor: grab;
      transition: 0.5s;
      transform: scale(1.1) rotate(20deg);
    }
    svg:active {
      cursor: grabbing;
      transition: 0.2s;
      transform: scale(0.9) rotate(-20deg);
    }
  }
}

.no-js {
  background-color: #151515;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Manrope', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
  font-weight: bolder;
}

h1 {
  font-size: 32px;
}
h2 {
  font-size: 28px;
}
h3 {
  font-size: 24px;
}
h4 {
  font-size: 20px;
}
h5 {
  font-size: 17px;
}
h6 {
  font-size: 14px;
}

body {
  margin: 0;
  font-family: 'Manrope', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  background-color: #e7e7e7;
}

a {
  font-family: 'Manrope', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
  font-weight: bold;
}

a:hover {
  text-decoration: none;
  /* make sure browsers use the pointer cursor for anchors, even with no href */
  cursor: pointer;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: $whiteStemdo;
  text-decoration: none;
  background-color: #373a3c;
}

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */

.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

/* ==========================================================================
Main page styles
========================================================================== */

.hipster.img-fluid {
  display: inline-block;
  width: 347px;
  height: 497px;
  background-size: contain;
}

/* wait autoprefixer update to allow simple generation of high pixel density media query */

@media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
  .hipster {
    background-size: contain;
  }
}

/* ==========================================================================
Generic styles
========================================================================== */

/* Error highlight on input fields */

.valid[required],
.valid.required {
  border-left: 5px solid green;
}

.invalid:not(form) {
  border-left: 5px solid red;
}

/* other generic styles */

.jh-card {
  width: 100%;
  padding: 1.5%;
  // margin-top: 25px !important;
  margin: 1% 1% 0% 0% !important;
  border: none;
  background-color: rgb(247, 243, 243);
}

.error {
  color: $whiteStemdo;
  background-color: $redStemdo;
}

.pad {
  padding: 10px;
}

.w-40 {
  width: 40% !important;
}

.w-60 {
  width: 60% !important;
}

.break {
  white-space: normal;
  word-break: break-all;
}

.readonly {
  background-color: #eee;
  opacity: 1;
}

////////////////////////
////////////////////////
.hand,
[jhisortby] {
  cursor: pointer;
}

.card {
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 20px;
}

/* ==========================================================================
Custom alerts for notification
========================================================================== */

.alerts {
  .alert {
    text-overflow: ellipsis;

    pre {
      background: none;
      border: none;
      font: inherit;
      color: inherit;
      padding: 0;
      margin: 0;
    }

    .popover pre {
      font-size: 10px;
    }
  }

  .toast {
    position: fixed;
    width: 100%;

    &.left {
      left: 5px;
    }

    &.right {
      right: 5px;
    }

    &.top {
      top: 55px;
    }

    &.bottom {
      bottom: 55px;
    }
  }
}

@media screen and (min-width: 480px) {
  .alerts .toast {
    width: 50%;
  }
}

/* ==========================================================================
entity tables helpers
========================================================================== */

/* Remove Bootstrap padding from the element
http://stackoverflow.com/questions/19562903/remove-padding-from-columns-in-bootstrap-3 */

@mixin no-padding($side) {
  @if $side== 'all' {
    .no-padding {
      padding: 0 !important;
    }
  } @else {
    .no-padding-#{$side} {
      padding-#{$side}: 0 !important;
    }
  }
}

@include no-padding('left');
@include no-padding('right');
@include no-padding('top');
@include no-padding('bottom');
@include no-padding('all');

/* bootstrap 3 input-group 100% width
http://stackoverflow.com/questions/23436430/bootstrap-3-input-group-100-width */

.width-min {
  width: 1% !important;
}

/* ==========================================================================
entity detail page css
========================================================================== */

.row.jh-entity-details {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 10px;
  line-height: 1.5;
}

@media screen and (min-width: 768px) {
  .row.jh-entity-details > {
    dt {
      float: left;
      overflow: hidden;
      clear: left;
      text-align: right;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0.5em 0;
    }

    dd {
      border-bottom: 1px solid #eee;
      padding: 0.5em 0;
      margin-left: 0;
    }
  }
}

/* ==========================================================================
ui bootstrap tweaks
========================================================================== */

.nav,
.pagination,
.carousel,
.card-title a[aria-selected='false'] {
  cursor: not-allowed;
}

.datetime-picker-dropdown > li.date-picker-menu div > table .btn-secondary,
.uib-datepicker-popup > li > div.uib-datepicker > table .btn-secondary {
  border: 0;
}

.datetime-picker-dropdown > li.date-picker-menu div > table:focus,
.uib-datepicker-popup > li > div.uib-datepicker > table:focus {
  outline: none;
}

.thread-dump-modal-lock {
  max-width: 450px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* jhipster-needle-scss-add-main JHipster will add new css style */

@media only screen and (max-width: 768px) {
  .cookieFlag {
    p {
      font-size: 12px;
    }

    .cookiesvg {
      svg {
        height: 70%;
        margin-top: 0.2em;
      }
    }
  }
}

@media only screen and (max-width: 425px) {
  .cookieFlag {
    width: 85vw;
    height: 12vh;

    p {
      font-size: 12px;
      padding-top: 2vh;
    }

    .cookiesvg {
      display: none;
      // svg {
      //   height: 70%;
      //   margin-top: 0.2em;
      // }
    }

    .close {
      width: 9vw;
    }
  }
}

// @media only screen and (max-width: 770) {
//   .cookieFlag {
//     width: 20%;
//   }
// }
