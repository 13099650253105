
.close-button {
  cursor: pointer;
  border: 1px solid var(--v-border-darken1);
  border-radius: 50%;
  padding: 1px;
}

.close-button:hover {
  background-color: var(--v-background-darken1);
}

.detail-link {
  display: flex;
  align-items: center;
}
