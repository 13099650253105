.back-button {
  height: 24px !important;
  max-width: 24px !important;
  min-width: 24px !important;
  max-height: 24px !important;
  width: 24px !important;
  position: relative;

  &::after {
    display: none !important;
  }
}

.back-button .v-btn__content {
  padding: 0;
}

.back-button .back-icon {
  width: 24px;
  height: 24px;
}
