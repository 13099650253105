/* after changing this file run 'npm run webapp:build' */

/***************************
put Sass variables here:
eg $input-color: red;
****************************/

/*
VARIABLES GENERALES DE SUMA IMPORTANCIA
- $STEMDOCOLOR
*/
$blueStemdo: #4237ff !default;
$purpleStemdo: #8859ff !default;
$redStemdo: #ef2630 !default;
$greenStemdo: #00c2b1 !default;
$blackStemdo: #222226 !default;
$darkGreyStemdo: #606061 !default;
$greyStemdo: #dbdbe0 !default;
$lightGreyStemdo: #ececef !default;
$whiteStemdo: #f9f9f9 !default;

$primary: $greenStemdo !default;
$secondary: $greyStemdo !default;
$info: $blueStemdo !default;
$warning: $purpleStemdo !default;
$danger: $redStemdo !default;
$light: $whiteStemdo !default;
$dark: $blackStemdo !default;

// Override Boostrap variables
// NO TOCAR ABAJO
@import '~bootswatch/dist/minty/variables';
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-vue/src/index.scss';
@import '~bootswatch/dist/minty/bootswatch';
// NO TOCAR ARRIBA

@import 'global';
@import 'global-var';
@import 'navbar';
@import 'footer';
@import 'filter';
@import 'user-detail';
@import 'multiselect';
@import 'user-active';
@import 'user-operation';
@import 'stemdocomponents';
@import 'fonts';
@import 'vuetify';

$primary: $blueStemdo !default;
$secondary: $greyStemdo !default;
$success: $green !default;
$info: $greenStemdo !default;
$warning: $yellow !default;
$danger: $redStemdo !default;
$light: $whiteStemdo !default;
$dark: $blackStemdo !default;

/* Dirty fix on navbar active link with bootswatch theme */

// nav li.router-link-active .navbar-dropdown-menu {
//   color: $navbar-dark-active-color;
// }

/* jhipster-needle-scss-add-vendor JHipster will add new css style */
