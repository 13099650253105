<template>
  <v-navigation-drawer color="background darken-1" permanent left app data-cy="main-drawer">
    <!-- Logo / Brand -->
    <template v-slot:prepend>
      <v-list-item style="max-height: 32px; min-height: 32px !important" class="m-0 p-0"></v-list-item>
      <v-list-item class="ml-4 mt-n2" data-cy="drawer-logo-container">
        <v-list-item-icon class="mt-0">
          <v-img :width="164" src="/content/images/stemdo-black-logo.png" data-cy="drawer-logo-img"></v-img>
        </v-list-item-icon>
      </v-list-item>
    </template>

    <!-- Lista de items del drawer -->
    <v-list width="100%" class="custom-nav mt-4" dense nav data-cy="drawer-main-list">
      <div active-class="active-item">
        <MainDrawerItem
          v-for="(item, index) in items"
          :key="item.title"
          :item="item"
          :class="[{ 'mb-3': index !== items.length - 1 }]"
          data-cy="drawer-list-item"
        />
      </div>
    </v-list>

    <!-- Parte inferior del drawer -->
    <template v-slot:append>
      <v-list width="100%" class="mb-5" dense nav data-cy="drawer-append-list">
        <v-list-item class="ml-3" data-cy="drawer-lang-select-item">
          <SSelectLang data-cy="drawer-lang-select" />
        </v-list-item>

        <v-list-item class="ml-3" data-cy="drawer-logout-item">
          <SButtonLogout data-cy="drawer-logout-button" />
        </v-list-item>

        <v-list-item class="ml-3 justify-center" style="max-height: 18px; min-height: 18px" data-cy="drawer-version-item">
          <span class="text--text text--lighten-1 text-body-3" data-cy="drawer-version-text"> v{{ version }} </span>
        </v-list-item>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script lang="ts" src="./mainDrawer.ts"></script>

<style scoped>
/* Style for navigation drawer */
.v-navigation-drawer >>> .v-navigation-drawer__border {
  display: none;
}

/* Style for non active elements in the nav */
.v-navigation-drawer >>> .v-list-item__content .v-list-item__title {
  font-size: 16px;
  color: var(--v-text-base);
}

.v-navigation-drawer >>> .v-list-group__header .v-list-group__header__append-icon i {
  color: var(--v-text-lighten1);
}

/* Style for group items in nav */
.v-navigation-drawer >>> .v-list-group__header {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
</style>
