
.close-button {
  cursor: pointer;
  border: 1px solid var(--v-border-darken1);
  border-radius: 50%;
  padding: 1px;
}

.detail-link {
  display: flex;
  align-items: center;
}

::v-deep .v-dialog--active {
  height: 100% !important;
  max-height: 100% !important;
  min-height: 100% !important;
  margin: 0px !important;
  position: fixed !important;
  top: 0% !important;
  right: 0% !important;
  background-color: white;
}

.close-button:hover {
  background-color: var(--v-background-darken1);
}

.smodalright-title {
  color: var(--v-text-primary) !important;
}

.v-dialog__content {
  justify-content: flex-end !important;
}
