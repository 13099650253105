
.v-tooltip__content {
  background-color: rgb(0, 0, 0);
  opacity: 1;
  color: #ffffff;
  border-radius: 4px;
  max-height: 5.5em;
}

.theme--light.v-chip:not(.v-chip--active) {
  color: #606061 !important;
  background-color: #ececef !important;
  font-size: 12px !important;
  font-family: Manrope !important;
  font-weight: 400 !important;
}

::v-deep .v-chip__content {
  font-size: 12px !important;
}

@import './stemdoerCard.scss';
