<template>
  <div>
    <v-hover v-if="!item.items">
      <template v-slot:default="{ hover }">
        <v-list-item
          :class="[
            'ml-5 pl-0 ',
            !isActiveLink(item.activePaths, $route.path) && hover ? 'hover-item' : '',
            isActiveLink(item.activePaths, $route.path) ? 'active-item v-list-item--active' : '',
          ]"
          @click="goToPage(item.path, $route.path)"
          :key="item.title"
          :disabled="item.disable"
        >
          <ItemContent :item="item" :isActive="isActiveLink(item.activePaths, $route.path)" :hover="hover" />
        </v-list-item>
      </template>
    </v-hover>

    <ItemGroup v-else :item="item" :isActiveLink="isActiveLink" />
  </div>
</template>

<script lang="ts" src="./mainDrawerItem.ts"></script>

<style scoped>
/* Style for active elements in the nav */
.active-item::before {
  background: linear-gradient(to right, rgba(66, 55, 255, 0.75) 0%, transparent 95%);
  background-color: transparent;
}

.active-item >>> .v-list-item__content .v-list-item__title {
  font-size: 16px !important;
  color: var(--v-primary-base) !important;
  font-weight: 700 !important;
}

/* Style for hover elements in the nav */
.v-list-item--link:hover::before {
  background-color: transparent !important;
}
.hover-item::before {
  background: linear-gradient(to right, rgba(66, 55, 255, 1) 0%, transparent 95%);
  background-color: transparent;
}
/* Text color when hover */
.hover-item >>> .v-list-item__content .hover-item-content__title {
  font-size: 16px !important;
  color: var(--v-primary-base) !important;
}
</style>
