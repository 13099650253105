.filter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0.2em;
  padding: 0.2em;
  background-color: $lightGreyStemdo;
}

.filtro {
  border-radius: 5px;
  font-weight: 600;
  display: flex;
  align-items: center;
  margin: 0.3em;
  padding: 0.5em;
  justify-content: space-evenly;

  .dateInputs {
    margin-top: -1.2em;

    .datepicker {
      min-width: 120px;
      border: none;
      border-radius: 5px;
      height: 2.5em;
      margin: 0.5em;
      text-align: center;
      color: $blackStemdo;
      padding-right: 10px;
      font-weight: 600;
    }
  }

  .multiselect {
    display: inline-block;
    // position: absolute;
    width: 38em;
    margin-left: 0.3em;
  }

  button {
    font-size: 1.2em;
    border: none;
    border-radius: 8px;
    font-weight: bold;
    text-justify: auto;
    text-align: center;
    margin: 0.4em;
    text-decoration: none;
    transition: all 0.25s ease-in-out;
    position: relative;
    padding: 10px 17px;
    min-width: 180px;
    min-height: 40px;
  }

  button::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 3px;
    background-image: url('../images/en.jpg');
    background-repeat: cover;
    background-position: center center;
    background-size: 100%;
    opacity: 0.3;
    z-index: -1;
    border-radius: 8px;
  }

  .pressed {
    -webkit-text-stroke: 1px $darkGreyStemdo;
    color: $blackStemdo;
    background-color: $darkGreyStemdo !important;
    box-shadow: inset 3px 3px 6px #09321495, inset -3px -3px 5px lighten(rgba(135, 135, 135, 0.285), 10%) !important;
    transform: translateY(4px);
  }

  .noPressed {
    color: #7e7e7e;
    background-color: #ffffff8f;
    box-shadow: 3px 4px 8px #1a00585a, -5px -5px 7px #fbb3b345;
  }
}

.orden {
  border-radius: 5px;
  font-weight: 600;
  display: flex;
  align-items: center;
  margin: 0.3em;
  padding: 0.5em;
  justify-content: space-around;

  .dateInputs {
    display: flex;
    align-items: center;
    margin-top: -1.2em;

    .datepicker {
      min-width: 120px;
      border: none;
      border-radius: 5px;
      height: 2.5em;
      margin: 0.5em;
      text-align: center;
      color: $darkGreyStemdo;
      padding-right: 10px;
      font-weight: 600;
    }
  }

  .multiselect {
    display: inline-block;
    width: 50em;
    height: 50px;
    margin: 0.3em;
  }
}

//modal de usuario
.modal-dialog {
  width: 1000px !important;

  div[id*='user-details'] {
    .modal-title {
      font-size: 2.6em;
      color: $blackStemdo;
      font-family: 'Manrope', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      margin-top: -0.2em;
      margin-bottom: -0.4em;
    }

    .modal-header {
      background-color: $greenStemdo;
    }

    .modal-body {
      background-color: $whiteStemdo;
      color: $blackStemdo;
      font-family: 'Manrope', sans-serif;
    }

    .minilogo {
      filter: invert(100);
      width: 90px;
      height: 80px;
      padding: -15em;
      // background-color: black;
      margin-top: -0.22em;
      margin-left: -0.3em;
      object-fit: cover;
    }
  }
}
