<template>
  <div class="custom-v-search">
    <v-text-field
      v-model="searchText"
      density="compact"
      :label="placeholder"
      prepend-inner-icon="mdi-magnify"
      variant="solo-filled"
      flat
      solo
      dense
      hide-details
      class="custom-text-field text-body-1"
      @input="handleChange"
      data-cy="s-search"
    ></v-text-field>
  </div>
</template>

<script lang="ts" src="./sSearch.ts" />

<style scoped>
::v-deep input::placeholder {
  color: var(--v-text-lighten1) !important;
  font-size: 16px;
  font-weight: 500;
}

.custom-text-field ::v-deep input {
  color: var(--v-text-base) !important;
}

.custom-v-search ::v-deep .v-input__slot {
  background-color: var(--v-background-base) !important;
  border: 1px solid var(--v-background-darken2) !important;
  border-radius: 25px;
  height: 45px;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}

.custom-text-field ::v-deep input::placeholder,
.custom-text-field ::v-deep .v-label,
.custom-text-field ::v-deep .v-icon {
  color: var(--v-text-lighten2) !important;
}

.v-input--is-focused ::v-deep .v-input__slot {
  border-color: var(--v-border-darken1) !important;
}
</style>
