.team-request-table {
  box-shadow: none !important;
}

.chip-container {
  display: flex;
  flex-direction: row;
}

.overlapping-chip {
  background-color: var(--v-background-darken3) !important;
  color: white !important;
  margin-right: -8px;
  padding: 0 !important;
  font-family: 'Manrope' !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  width: 30px !important;
  height: 30px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
  background-color: var(--v-background-darken3) !important;
  border: 1px solid white !important;
}

.client-info {
  min-height: 63px;
  padding: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--v-text-base);
}

.status-container {
  display: flex;
  align-items: center;

  span {
    border: 1px solid var(--v-text-lighten3);
    background-color: var(--v-text-lighten4);
    border-radius: 50px;
  }
}

.menu-right {
  right: 0;
}
