
@import '@content/scss/vuetify.scss';

.custom-v-selector ::v-deep .v-input__slot {
  border-radius: 4px;
  height: 45px;
  min-width: 90px;
  color: var(--v-text-base) !important;
  margin-bottom: 0px !important;
  background: var(--v-background-base) !important;
}

.custom-v-selector-default ::v-deep .v-input__slot {
  border: 1px solid var(--v-border-base) !important;
}

.custom-v-selector-error ::v-deep .v-input__slot {
  border: 1px solid var(--v-quaternary-base) !important;
}

.v-menu__content .v-list >>> .v-list-item--active::before {
  background-color: transparent !important;
}

.v-menu__content .v-list >>> .selected-item {
  color: var(--v-text-base) !important;
}

/*------- placeholder --------*/
::v-deep .v-select__slot .v-label {
  @extend .text-body-1;
}

.custom-v-selector-default ::v-deep .v-select__slot .v-label {
  color: var(--v-text-lighten2) !important;
}

.custom-v-selector-floating ::v-deep .v-select__slot .v-label {
  color: var(--v-text-lighten1) !important;
}

.custom-v-selector-error ::v-deep .v-select__slot .v-label {
  color: var(--v-quaternary-base) !important;
}
/*------- placeholder --------*/

.v-input--is-focused ::v-deep .v-input__slot {
  border-color: var(--v-border-darken1) !important;
}

.custom-v-selector ::v-deep .v-text-field__details {
  display: none;
}

.custom-v-selector ::v-deep .v-select__selections {
  flex-wrap: nowrap !important;
}

.custom-v-selector ::v-deep .v-select__selections span {
  display: inline-block;
  max-width: 100%;
  min-width: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
  letter-spacing: normal !important;
}

.custom-v-selector ::v-deep .v-list-item__title .v-list-item__content {
  font-size: 30px;
}

.select-container {
  min-width: 195px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
