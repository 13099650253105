
@import './stemdoerDetail.scss';

.v-tooltip__content {
  background-color: rgb(0, 0, 0);
  opacity: 1;
  color: #ffffff;
  border-radius: 4px;
  max-height: fit-content;
}

.v-tooltip__content {
  background-color: var(--v-background-darken3) !important;
  opacity: 1;
  color: var(--v-btnPrimaryText-base) !important;
  border-radius: 4px;
}

::v-deep .overlay-text {
  font-size: 48px !important;
}
