#s-button-card {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  p {
    color: var(--v-text-base);
  }

  .btn-main {
    min-width: 130px;
    display: flex;
    justify-content: center;
    background: white !important;
    min-height: 53px !important;
    max-height: 53px !important;
  }
  .btn-main:hover {
    background-color: var(--v-background-darken1) !important;
  }

  .first-btn {
    border-radius: 4px 0 0 0;
    border-left: 1px solid var(--v-border-base) !important;
    border-right: 1px solid var(--v-border-base) !important;
    border-top: 1px solid var(--v-border-base) !important;
    margin-left: 0px !important;
  }

  .other-btn {
    border-right: 1px solid var(--v-border-base) !important;
    border-top: 1px solid var(--v-border-base) !important;
  }

  .last-btn {
    border-radius: 0 4px 0 0;
    border-right: 1px solid var(--v-border-base) !important;
    border-top: 1px solid var(--v-border-base) !important;
  }

  .active {
    background-color: var(--v-background-darken1) !important;
    height: 60px !important;
    min-height: 60px !important;
    max-height: 60px !important;
    border-bottom: 2px solid var(--v-background-darken1) !important;
    border-radius: 4px 4px 0 0;
    margin-bottom: -2px;
  }

  h2 {
    color: white;
    line-height: 1.2;
    border-radius: 40%;
  }

  #all {
    h2 {
      background-color: var(--v-background-darken3);
    }
  }
  .off {
    border-radius: 0;
    border-bottom: none;
  }

  .aux-btn {
    background-color: white !important;
    border-radius: 4px;
    border: 1px solid var(--v-border-base);
    height: 45px;
    min-height: 45px;
  }

  .selected-aux-btn {
    border: 2px solid var(--v-text-base) !important;
    font-weight: 800;
  }
}
