.team-request-table {
  box-shadow: none !important;
}
.menu-right {
  right: 0;
}

#s-button-card {
  .btn-main {
    background: rgba(255, 255, 255, 0.5) !important;
    width: 127px !important;
    min-width: 127px !important;
    max-width: 127px !important;
    height: 80px !important;
    min-height: 80 !important;
    max-height: 80 !important;
    border: 1px solid var(--v-background-darken2) !important;
  }

  .progress-circular-custom {
    color: var(--v-background-darken2) !important;
    caret-color: var(--v-background-darken2) !important;
  }
}
