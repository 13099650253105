
/* Style for vertical line before elements in the nav */
.vl-wrapper {
  display: flex;
  justify-content: center;
  width: 24px;

  .vl {
    position: absolute;
    top: 0px;

    background-color: var(--v-border-darken1);
    width: 2px;
    height: 44px;
  }

  .vl-last {
    position: absolute;
    top: 0px;

    background-color: var(--v-border-darken1);
    width: 2px;
    height: 26px;
  }

  .vl-active {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 2px;
    /* Default color for the line */

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 50%;
      background-color: var(--v-border-darken1); /* Default color for top and bottom parts */
    }

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 42%;
      background-color: var(--v-border-darken1); /* Default color for top and bottom parts */
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
    }

    .middle {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      height: 15px; /* Adjust height as needed */
      background-color: var(--v-primary-base); /* Color for the middle part */
      width: 100%;
    }
  }

  .vl-active-last {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 2px;
    /* Default color for the line */

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 50%;
      background-color: var(--v-border-darken1); /* Default color for top and bottom parts */
    }

    &::before {
      top: 0;
    }

    .middle {
      position: absolute;
      top: 46%;
      transform: translateY(-50%);
      height: 11px; /* Adjust height as needed */
      background-color: var(--v-primary-base); /* Color for the middle part */
      width: 100%;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}
.cursor-default {
  cursor: default;
}
