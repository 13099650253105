#user-active {
  display: flex;
  flex-direction: column;
  align-items: center;

  .btnRequestStemdoers {
    svg {
      margin-right: -5em;
    }
  }

  .noData {
    padding: 2em;
    font-weight: 800;
    font-size: 24px;
    text-align: center;
  }

  .card-containerIMG {
    width: 11em;

    .card-img {
      width: 11em;
      border-radius: 10px;
    }

    .card-imgStemdoer {
      // width: 20em;
      border-radius: 50px;
    }
  }

  .cardTabs > div > div:nth-child(odd) {
    margin-bottom: -1em;
  }

  .cardTabs > div > div:nth-child(odd) > ul > li > a[aria-selected='false'] {
    background-color: rgb(240, 240, 240);
  }

  .cardTabs > div > div:nth-child(odd) > ul > li > a[aria-selected='true'] {
    background-color: $greenStemdo;
    color: white !important;
  }

  .cardTabs > div > div:nth-child(odd) > ul > li > a:active {
    color: white !important;
  }

  .cardTabs > div > div:nth-child(odd) > ul > li > a[aria-selected='true']:visited {
    color: white !important;
  }

  .cardTabs > div > div:nth-child(odd) > ul > li > a[aria-selected='true']:hover {
    background-color: #20b19b;
    color: white !important;
  }

  .cardTabs > div > div:nth-child(odd) > ul > li {
    border-radius: 0px !important;
  }

  .cardTabs {
    margin-top: 0.75em;
    margin-bottom: 2em;
    display: flex;
    align-items: center;
    border: 3px solid rgb(231, 231, 231);
    background-color: $whiteStemdo;
    padding: 1.5em;
    width: 80vw !important;

    .tabs {
      display: flex;
      justify-content: center;
      flex-wrap: nowrap;
      flex-direction: column;
      width: 100%;
    }
    .tableClient {
      width: 100%;
    }

    .nav-link {
      background-color: $whiteStemdo;
    }

    .nav-link:focus {
      transition: 0.4s;
      color: $blueStemdo !important;
    }

    .nav-link:active {
      transition: 0.4s;
      color: $blueStemdo !important;
    }
  }

  .tableClient {
    th {
      border-right: 8px;
      font-weight: 800;
      padding: 0.4em;
      background-color: $greenStemdo;
      color: $whiteStemdo;
      border: 8px;
    }

    td {
      border: 1px solid rgba(109, 109, 109, 0.062);
      padding: 6px;
      background-color: rgb(237, 237, 237);
    }

    td:hover {
      background-color: rgb(228, 228, 228);
    }
  }

  a .disabled {
    cursor: not-allowed !important;
  }

  td svg {
    width: 50%;
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    transition: 0.2s;
  }

  td svg:hover {
    transition: 0.2s;
    transform: scale(1.15);
    cursor: pointer;
  }
}

.startCardModal {
  .cardContainer {
    margin-bottom: 0.5em !important;
  }

  .activeUserPill {
    padding: 3px 7px;
    border-radius: 10px;
    background-color: #f2f2f2;
    color: #333;
    font-size: 0.75em;
    margin: 3px;
    text-transform: uppercase;
    font-weight: 600;
  }

  .activeUserExp {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
  }
}
