@import '../../../../content/scss/variables';

.popup {
  margin-top: 35px;
  position: fixed;
  z-index: 1000;
  width: fit-content;
  height: 90px;
  min-width: fit-content;
  flex-shrink: 0;
  right: 47px;
  border: 1px solid $lightGreyStemdo;
  border-radius: var(--border-radius, 4px);
  background: white;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.155);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  animation: expandFromCenter 0.2s ease-out forwards;

  &.Sconfirm {
    border-color: $greenStemdo;
  }

  &.Serror {
    border-color: $redStemdo;
  }

  .close-button {
    position: absolute;
    top: 0;
    right: 0;
    transition: all 0.2s ease;
    cursor: pointer;
    width: 18px;
    margin-right: 4px;
  }

  .close-button:hover {
    fill: $blackStemdo;
  }

  .close-button:active {
    transform: scale(0.9);
  }

  .content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 0px 16px;
  }

  .status {
    margin: 0 5px;

    &.Sconfirm svg circle {
      fill: $greenStemdo;
      color: white;
    }

    &.Serror svg circle {
      fill: $redStemdo;
      color: white;
    }
  }

  .message {
    margin: 0 8px;
    align-self: center;
    color: $blackStemdo;
    width: fit-content;
  }

  .span {
    color: $blackStemdo;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  @keyframes expandFromCenter {
    0% {
      transform: scale(0);
      opacity: 0;
    }

    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
}
