<template>
  <v-autocomplete
    :label="$t(placeholder)"
    :items="items"
    v-model="selectedItems"
    item-value="id"
    item-text="desc"
    class="custom-v-autocomplete"
    :style="customStyle"
    :height="inputHeight"
    return-object
    @change="handleChange"
    @blur="handleBlur"
    @focus="handleFocus"
    :disabled="disabled"
    multiple
    dense
    flat
    solo
  >
    <template v-slot:selection="{ index }">
      <span v-if="!labelDisplaySelection && index === 0" class="pt-1 pb-1 text-body-1 text--text text--lighten-1">
        {{ labelDisplay }}
      </span>
      <span v-if="labelDisplaySelection && index === 0" class="pt-1 pb-1 text-body-1-medium text--text text--base">
        {{ labelDisplay }}
      </span>
    </template>
    <template v-slot:item="{ item }">
      <div
        :class="[
          'text--text',
          'text--base',
          { 'selected-item': isItemSelected(item) },
          { 'text-body-1-bold': isItemSelected(item) },
          { 'text-body-1': !isItemSelected(item) }
        ]"
      >
        {{ $t(item.desc) }}
      </div>
    </template>
  </v-autocomplete>
</template>

<script lang="ts" src="./sAutocompleteMultiple.ts" />

<style scoped>
.custom-v-autocomplete >>> .v-input__slot {
  border: 1px solid var(--v-border-base) !important;
  border-radius: 4px;
  margin-bottom: 0px !important;
}

.v-input--is-focused >>> .v-input__slot {
  border-color: var(--v-border-darken1) !important;
}

.custom-v-autocomplete >>> .v-text-field__details {
  display: none;
}

.custom-v-autocomplete >>> .v-select__selections {
  flex-wrap: nowrap !important;
}

.custom-v-autocomplete >>> .v-select__selections span {
  display: inline-block;
  max-width: 100%;
  min-width: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
  letter-spacing: normal !important;
}

.custom-v-autocomplete >>> .v-select__slot label {
  color: var(--custom-color) !important;
}

.custom-v-autocomplete >>> .v-select__slot .v-input__append-inner {
  cursor: pointer;
}

.v-menu__content .v-list >>> .v-list-item--active::before {
  background-color: transparent !important;
}

.v-menu__content .v-list >>> .selected-item {
  color: var(--v-primary-base) !important;
}
</style>
