#user-details {
  color: $blackStemdo;
  font-family: 'Manrope', sans-serif;

  .skillsContent {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  #toolbarUD {
    i {
      margin: 0.25em;
    }
  }
  .logos {
    height: 9em;
    width: 9em;
    flex-direction: column;
    flex-wrap: wrap;
    margin-right: 0.3em;

    .imgLogos {
      margin: 0.2em;
    }
  }
  .row.logo {
    align-items: start;
  }

  .skillsCards {
    background-color: $greyStemdo;
    border-radius: 5px;
    width: auto;
    margin-left: 8px;
    margin-bottom: 5px;
    height: 2em;
    padding-left: 0.6em;
    padding-right: 0.6em;
    padding-top: 0.2em;
    text-align: center;
    font-weight: 250;
    color: $blackStemdo;
  }

  .hrLine {
    border: none;
    height: 2px;
  }

  .skills {
    background-color: $greyStemdo;
    border-radius: 5px;
    width: auto;
    margin-left: 8px;
    margin-bottom: 5px;
    height: 2em;
    padding-left: 0.5em;
    padding-top: 0.2em;
    text-align: center;
    font-weight: 250;
    color: $blackStemdo;

    .badge {
      color: $blackStemdo;
      margin-right: 0.5em;
      margin-left: 0.5em;
      padding: 0.2em;
    }
  }

  .card-img-selected {
    border-radius: 10px;
    width: 10em;
  }

  .col-img {
    margin-left: 2em;
  }

  .col-info {
    margin-top: 0.4em;
    margin-left: 1em;
    float: right;
    display: block;
  }

  .logo {
    width: 4em;
    height: 4em;
    padding-right: 5px;
  }

  .logos {
    margin-top: 1em;
    margin-left: 1em;
    left: 1px;
    bottom: 1px;
    float: right;

    img {
      transition: 0.3s;
      position: relative;
      z-index: 1;
    }

    img:hover {
      scale: 1.9;
      position: relative;
      z-index: 2;
      transition: 0.7s;
      z-index: 2;
    }
  }

  .centerPDF {
    display: flex;
    width: 100%;
  }

  .modal-title {
    width: 100%;
  }

  .btnPDF {
    background-color: #008c84;
    color: $whiteStemdo;
    border: none;
    padding: 10px 10px 10px 10px;
    font-size: 20px;
    margin-left: auto;
    margin-right: 3em;
    transform: scale(1.222);
    width: 2em;
    height: 3.15em;
    margin-top: -0.5px;
    margin-bottom: 2.5px;
  }

  .btnPDF svg {
    transition: 0.5s;
  }

  .btnPDF svg:hover {
    transition: 0.5s;
    transform: scale(1.2);
  }

  .btnPDF svg:active {
    transition: 0.5s;
    transform: scale(0.8);
  }

  .titleExp {
    text-align: center;
    color: rgb(75, 75, 187);
    font-family: 'Manrope', sans-serif;
  }

  .showScale {
    font-size: 0.79em;
    margin: 4px;
    color: rgb(60, 60, 142);
    font-style: normal;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .custom-tooltip {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #ffffff;
    border: 1px solid #ccc;
    padding: 5px;
    white-space: pre-line;
  }

  .expTitleDescription:hover .custom-tooltip {
    display: block;
  }

  .toolBarStExp {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin-top: -1em;
    padding-right: 0.3em;
  }

  .totalExp {
    overflow-y: scroll;
    height: 30em;

    .specs {
      display: flex;
      flex-direction: column;
      margin-top: 1em;
      margin-left: 1em;
      margin-right: 1em;

      // CSS -  fmgg.
      .expTitle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        min-height: 2.5em;
        max-height: 2.5em;
        margin-bottom: 0px;
        background-color: $blueStemdo;
        color: $whiteStemdo;
        padding-left: 0.3em;
        font-weight: bold;
        padding: 0.2em 0.2em 0.1em 0.3em;

        .expTitleDescription {
          max-height: 1.7em;
          min-height: 1.7em;
        }

        sub {
          opacity: 0.8;
        }
      }

      .expTitleNoValidated {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        min-height: 2.5em;
        max-height: 2.5em;
        margin-bottom: 0px;
        background-color: $redStemdo;
        color: $whiteStemdo;
        padding-left: 0.3em;
        font-weight: bold;
        padding: 0.2em 0.2em 0.1em 0.3em;

        sub {
          opacity: 0.8;
        }
      }

      .noValidation {
        background-color: #fff26d;
        margin: 0;
        text-align: center;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }

      .bodyExp {
        p {
          margin-left: 1em;
          margin-right: 1em;
        }

        padding: 0.2em 0.2em 0em 0.7em;
        background-color: $lightGreyStemdo;

        hr {
          margin-top: -0.3em;
        }
      }

      .showTech {
        display: flex;
        overflow: hidden;
        flex-wrap: wrap;
      }

      .stemdoNot {
        p {
          margin-left: 1em;
          margin-right: 1em;
        }
        .notiDiv {
          background-color: #fff26d;

          padding: 0.1em 0.7em 0.1em 0.7em;
          margin-left: -0.7em;
          margin-right: -0.2em;
          margin-bottom: -0.5em;
          font-size: 16px;
          word-spacing: 1px;
        }
        padding: 0.2em 0.2em 0em 0.7em;
        background-color: $lightGreyStemdo;

        hr {
          margin-top: -0.3em;
        }
      }
    }
  }

  .btnAddExp {
    border-radius: 8px;
    background-color: $greenStemdo;
    color: #fff;
    float: right;
    border: 2px #85d0bb solid;
    font-size: 0.75em;
    margin-right: 0em;
  }

  .btnAddCert {
    border-radius: 8px;
    background-color: $greenStemdo;
    color: #fff;
    float: right;
    border: 2px #85d0bb solid;
    font-size: 0.75em;
    margin-right: 0.2em;
  }

  .btnAddExp svg {
    transition: 0.2s;
  }

  .btnAddExp:active svg {
    transition: 0.2s;
    transform: scale(0.6);
  }

  .editBtn {
    i {
      margin-right: 5px;
      float: right;
    }

    font-size: 1em;
    padding: 0;
    height: 1em;
    width: 100%;
    border: none;
    background-color: transparent;
  }

  .printBt {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .col-md {
    margin-left: 1em;
  }

  li {
    margin-left: 1em;
  }

  .custom-label {
    margin-bottom: -0.2em;
    margin-left: 0.3em;
    background-color: $greenStemdo;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: $whiteStemdo;
    font-size: 16px;
    padding: 0em 0.5em 0em 0.5em;
  }
}
