
@import './stemdoerListSkeleton.scss';

::v-deep .full-height-image .v-skeleton-loader__image {
  height: 100% !important;
  width: 100% !important;
  border-radius: 0 !important;
}

::v-deep .v-skeleton-loader__chip {
  margin: 0 8px 8px 0 !important;
}

::v-deep .v-skeleton-loader__bone {
  background-color: var(--v-background-darken1) !important;
}

::v-deep .v-skeleton-loader__bone::after {
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.65) 50%,
    rgba(255, 255, 255, 0) 100%
  ) !important;
}

::v-deep .v-skeleton-loader__text {
  height: 14px !important;
  margin: 0 8px 8px 0 !important;
  max-width: 50% !important;
}

::v-deep .v-skeleton-loader__avatar {
  height: 37px !important;
  width: 37px !important;
  margin: 0 4px !important;
}
