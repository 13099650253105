#StemdoerAvatarImgFrame {
  background-color: #e2e0ff;
}

.stemdoer-fallback-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.overlay-text {
  font-size: 32px;
  color: var(--v-text-base);
  font-weight: 800;
  position: absolute;
  word-wrap: break-word;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
