<template>
  <div class="checkbox-english-wraper py-4 px-6" data-cy="english-switch-container">
    <v-switch class="custom-checkbox-english mt-0 pt-0" v-model="value" color="primary" @change="handleChange" data-cy="english-switch">
      <template v-slot:label>
        <span class="text-body-1 text--text text--base" data-cy="english-switch-label">
          {{ $t('explore.more-filters.modal.filter.filter-label-lang') }}
        </span>
      </template>
    </v-switch>
  </div>
</template>

<script lang="ts" src="./switchEnglish.ts"></script>

<style scoped>
.checkbox-english-wraper {
  border: 1px solid var(--v-border-base);
  border-radius: 4px;
}

.custom-checkbox-english {
  margin-bottom: 0 !important;
}

.custom-checkbox-english >>> .v-input__slot {
  margin-bottom: 0 !important;
}

.custom-checkbox-english >>> .v-input__slot label {
  margin-bottom: 0 !important;
}

.custom-checkbox-english >>> .v-messages {
  display: none;
}
</style>
