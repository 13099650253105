#stemdoers-wrapper {
  border-radius: var(--border-radius, 4px);
  background: var(--White, #fff);
  min-width: 100%;
  min-height: 100%;
}

.stemdoers-tip {
  span {
    color: var(--v-text-secondary, #606061) !important;
    max-width: 100%;
  }
}
