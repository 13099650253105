#requests-wrapper {
  border-radius: var(--border-radius, 4px);
  background: var(--White, #fff);
  min-width: 100%;
  min-height: 100%;

  #title-page {
    color: #222226;
  }
}
