#user-operationIn {
  .listExpUO {
    margin-top: 0.5em;
    width: 80%;

    .col-md-12 div {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
    }
  }
  .inputCurrentExp {
    text-align: center;
    color: $whiteStemdo;
    border-radius: 5px;
    justify-content: flex-start;
    align-items: center;
    display: flex;
    width: fit-content;
    background-color: $blueStemdo;
    transform: scale(1.1);
    padding: 0.2em;
    margin-left: 0.5em;
    label {
      background-color: $blueStemdo !important;
      margin-bottom: 2.2px !important;
    }
  }
  .form-group .col-md-12,
  .col-md-4 {
    margin-bottom: 0.5em;
    label {
      margin-bottom: -0.2em;
      margin-left: 0.3em;
      background-color: $greenStemdo;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      color: rgb(250, 250, 250);
      font-size: 16px;
      padding: 0em 0.5em 0em 0.5em;
    }
    #description {
      resize: none;
      min-height: 8.5em;
      max-height: 10em;
      height: max-content;
    }
  }

  .col-md-6 {
    margin-bottom: 0.5em;
    label {
      margin-bottom: -0.2em;
      margin-left: 0.3em;
      background-color: $blueStemdo;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      color: rgb(250, 250, 250);
      font-size: 16px;
      padding: 0em 0.5em 0em 0.5em;
    }
    #description {
      resize: none;
      min-height: 8.5em;
      max-height: 10em;
      height: max-content;
    }
  }

  .col-md-12 {
    margin-bottom: 0.5em;
    label {
      margin-bottom: -0.2em;
      margin-left: 0.3em;
      background-color: $blueStemdo;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      color: rgb(250, 250, 250);
      font-size: 16px;
      padding: 0em 0.5em 0em 0.5em;
    }
    #description {
      resize: none;
      min-height: 8.5em;
      max-height: 10em;
      height: max-content;
    }
  }

  .labelNew {
    margin-bottom: -0.2em;
    margin-left: 0.3em;
    background-color: $blueStemdo;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: rgb(250, 250, 250);
    font-size: 16px;
    padding: 0em 0.5em 0em 0.5em;
  }

  .pills {
    width: fit-content;
    span {
      background-color: $greenStemdo;
      border-radius: 8px;
      padding: 0.3em;
      color: whitesmoke;
      margin: 0.2em;
    }
  }
}
