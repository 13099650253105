.card-stemdoer {
  border: 1px var(--v-text-lighten3) solid;
  border-radius: 4px;
  box-shadow: 0px 1px 0px var(--v-text-lighten3) !important;
}

.card-stemdoer--hover {
  background-color: var(--v-background-darken1);
  cursor: pointer;
}

.v-chip:not(.v-chip--active) {
  color: var(--v-text-lighten1) !important;
  background-color: var(--v-background-darken2) !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.color-text-black {
  color: var(--v-text-base);
}

.input-background {
  background-color: var(--v-background-base);
}

.btn-data {
  display: flex;
  justify-content: center;
  width: 30px;
  height: 30px;
  border: 1px var(--v-border-darken1) solid;
  border-radius: 4px;
}
