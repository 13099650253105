<template>
  <div class="filter-tag px-3 py-2 d-inline-flex align-center">
    <div class="pl-1 mr-1 font-weight-regular">{{ tagText }}</div>
    <div class="tag-close" @click="handleClose">
      <v-img :width="26" src="/content/svgs/close-tag.svg"></v-img>
    </div>
  </div>
</template>

<script lang="ts" src="./filterTag.ts"></script>

<style scoped>
.filter-tag {
  color: var(--v-text-base);
  border: 1px solid var(--v-text-base);
  border-radius: 40px;
}
.tag-close {
  cursor: pointer !important;
}
</style>
