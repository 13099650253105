
::v-deep .modal-span {
  color: var(--v-text-lighten1) !important;
  word-wrap: break-word;
}

::v-deep .input-form-stemdo {
  font-size: 16px;
  background-color: #ffffff;
  border: 1px solid var(--v-border-base) !important;
  display: block;
  width: 100%;
  height: 45px;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: var(--v-background-darken3) !important;
  background-clip: padding-box;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &::placeholder {
    color: var(--v-text-lighten2) !important;
  }
}

::v-deep .input-form-stemdo:focus {
  color: var(--v-background-darken3) !important;
  outline: 1px solid rgba($color: #dbdbe0, $alpha: 1);
}

::v-deep .v-input__slot {
  border: 1px solid var(--v-text-lighten3);
  border-radius: 4px;
  flex-shrink: 0;
  margin: 12px 0px;
}

::v-deep .v-text-field .v-input__slot {
  cursor: pointer;
  background-color: #ffffff !important;
  border: 1px solid var(--v-text-lighten3) !important;
  border-color: var(--v-text-lighten3) !important;
  min-height: 45px !important;

  input {
    cursor: pointer;
  }

  ::placeholder {
    color: var(--v-text-lighten1) !important;
  }
}

::v-deep .v-input__prepend-inner {
  max-width: 24px;
  margin: 11px 12px 11px 0px !important;
  align-items: center;
  display: flex;
  justify-content: center;
}

::v-deep .v-input__append-inner {
  max-width: 24px;
  margin: 11px 12px 11px 0px !important;
  align-items: center;
  display: flex;
  justify-content: center;
}

::v-deep .v-date-picker-table .v-btn {
  border-radius: 4px;
  color: var(--Body, var(--Colors-Body, var(--v-text-base)));
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

::v-deep .date-field .v-input__icon--prepend-inner {
  margin-right: 12px;
}

.clear-end-date-btn {
  z-index: 1;
  font-size: 12px;
  color: var(--v-btnPrimary-base) !important;
  margin: 8px 16px 0px 0px;
  font-weight: 400;
  word-wrap: break-word;
  height: auto;
  min-width: auto;
  cursor: pointer;
}

.clear-end-date-btn:hover {
  font-weight: 500;
  transition: all 0.1s ease-in-out;
}

.span-option {
  font-size: 12px;
  color: var(--v-btnPrimary-base) !important;
  font-weight: 400;
  word-wrap: break-word;
  text-decoration: underline;
  margin: -6px 0px 12px 0px;
  padding: 3px;
  text-align: left;
}

.span-option span:hover {
  transition: all 0.1s ease-in-out;
  cursor: pointer;
  font-weight: 500;
}

::v-deep .v-text-field--outlined fieldset {
  border: none !important;
}

:v-deep .v.menu {
  top: 355px !important;
  left: 207px !important;
  transform-origin: center !important;
}

::v-deep .no-hover .v-input__slot:hover {
  border: 1px solid var(--v-border-base) !important;
  background-color: inherit !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

::v-deep .no-hover input::placeholder {
  color: var(--v-text-lighten1) !important;
}

::v-deep .no-hover .v-input__slot:hover:focus {
  color: var(--v-border-darken3);
  outline: 1px solid rgba($color: #dbdbe0, $alpha: 1);

  &::placeholder {
    color: var(--v-text-lighten1) !important;
  }
}

::v-deep .v-text-field__details {
  display: none;
}

::v-deep .modal-footer {
  display: none;
}

.v-tooltip__content {
  background-color: var(--v-background-darken3) !important;
  opacity: 1;
  height: 33px;
  color: var(--v-btnPrimaryText-base) !important;
  border-radius: 4px;
}

.tooltip-right::before {
  content: ' ';
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -6px;
  border-width: 6px;
  border-style: solid;
  border-top: solid 6px transparent;
  border-bottom: solid 6px transparent;
  border-left: solid 6px transparent;
}

.tooltip-right.primary::before {
  border-right: solid 6px var(--v-background-darken3) !important;
}

.link-button {
  max-width: 22px !important;
  cursor: pointer;
  transition: transform 0.1s;
}

.link-button:active {
  transform: scale(0.9) translateY(2px);
}
