@import '@content/scss/variables';

#login {
  .input-form-stemdo {
    background-color: white;
    border: 1px solid $lightGreyStemdo;
    display: block;
    width: 100%;
    height: 45px;
    padding: 0.375rem 0.75rem;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    color: $blackStemdo;
    background-clip: padding-box;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .input-form-stemdo:focus {
    color: $blackStemdo;
    outline: 1px solid rgba($color: $greyStemdo, $alpha: 1);
    &::placeholder {
      color: $greyStemdo;
    }
  }

  .input-error {
    color: $redStemdo;
    outline: 1px solid rgba($color: $redStemdo, $alpha: 1);
    position: relative;
  }

  .validation-text {
    margin-bottom: 10px;
  }

  .validation-text-container {
    margin-top: 6px;
    span {
      color: $greyStemdo;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-right: 19px;
    }

    .valid-password {
      color: $greenStemdo;
    }
    .invalid-password {
      color: $redStemdo;
    }
  }

  .span-error {
    padding-top: 0.5em;
    color: $redStemdo;
    font-size: 14px;
    font-weight: 800;
  }

  .input-error:focus {
    outline: 1px solid rgba($color: $greyStemdo, $alpha: 1);
  }

  ::-webkit-input-placeholder {
    color: $greyStemdo;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    --tw-bg-opacity: 1;
    -webkit-box-shadow: 0 0 0 40px rgba($greyStemdo, var(--tw-bg-opacity)) inset !important;
  }

  .input-form-group-stemdo {
    border: 0px solid $lightGreyStemdo;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
    border-radius: 0.25rem;
  }

  .form-title {
    h1 {
      color: $blackStemdo;
      margin-bottom: 8%;
    }
    p {
      margin-bottom: 4%;
      font-size: 16px;
    }
  }

  .login-container {
    display: flex;
    margin: 0;
    justify-content: space-between;
    min-height: fit-content;
  }

  .login-image {
    width: 100vw;
    height: 100vh;
    background-size: cover;
    position: relative;
    background-image: url('@content/images/signin-1.png');
    background-position: center;
    img {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 50%;
      margin-bottom: 60px;
      filter: invert(100%);
    }
  }

  .login-image::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 51.17%, #000 100%);
  }

  .login-form {
    position: relative;
    padding: 5px;
    border-radius: 30px 0px 0px 30px;
    background-color: $whiteStemdo;
    width: 56.8%;
    margin-left: -30px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;

    .form-text {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      color: $blackStemdo;
      position: absolute;
      top: 0;
      right: 0;
      padding: 2.5em 4em 1em 2.5em;
    }
  }

  .SForm {
    width: 55%;
  }

  .login-span {
    color: $darkGreyStemdo;
    font-weight: 600;
  }
  .login-remember {
    margin: 3.5% 0px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
  }

  .separator {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid $lightGreyStemdo;
    line-height: 0.1em;
    margin: 5% 0;
  }

  .login-error {
    display: flex;
    flex-direction: column;

    a {
      text-decoration-line: underline;
    }
  }
}
