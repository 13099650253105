#stemdoer-card {
  height: 16em;
  width: 100%;
  border-radius: 4px;
  border: 1px solid var(--v-border-base);
  box-shadow: 0px 1px 0px 0px #ececef;

  #StemdoerCardfirstColImg {
    border-bottom-left-radius: 3px;
    background: var(--v-background-darken1);
    height: 100%;
    min-width: 157px;
  }

  .chips {
    color: #606061 !important;
    font-size: 12px !important;
    font-family: Manrope !important;
    font-weight: 400 !important;
    word-wrap: break-word !important;
    background-color: #ececef !important;
    height: 31px !important;
    width: fit-content !important;
  }

  .available-label {
    width: 100%;
    height: 25px;
    background-color: white;
    border-radius: 4px;
    border: 1px solid #606061;

    .available-text {
      color: #606061;
      font-size: 10px;
      font-weight: 600;
      word-wrap: break-word;
    }
  }

  h4 {
    color: #222226;
    font-size: 16px;
    font-family: Manrope;
    font-weight: 800;
    word-wrap: break-word;
  }

  h5 {
    color: #222226;
    font-size: 14px;
    font-family: Manrope;
    font-weight: 600;
    word-wrap: break-word;
  }

  .color-text-black {
    color: #222226;
  }

  #StemdoerCardImgFrame {
    border-radius: 4px 0px 0px 4px;
  }

  .stemdoerBtnAdd {
    background-color: #4237ff;
    height: 160 !important;
    max-width: 417 !important;
    min-width: 417 !important;
    max-height: 160 !important;
    width: 417 !important;
    color: white;
    font-size: 16px;
    font-family: Manrope;
    font-weight: 800;
    word-wrap: break-word;
  }

  .stemdoer-card-container:hover {
    background-color: #f9f9f9;
    cursor: pointer;
  }
}
