
::v-deep .v-dialog--active {
  height: 100% !important;
  max-height: 100% !important;
  min-height: 100% !important;
  margin: 0px !important;
  position: fixed !important;
  top: 0% !important;
  right: 0% !important;
  background-color: white;
}

.btn-exit {
  position: fixed;
  margin-top: 2em;
  margin-left: -3em;
  z-index: -1;
  background-color: #f9f9f9;
  padding: 0.5em;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.v-dialog__content {
  justify-content: flex-end !important;
  margin-left: 20% !important;
}
