
@import '@content/scss/vuetify.scss';

.input-wrapper {
  padding-left: 12px;
  border: 1px solid var(--v-border-base);
  border-radius: 4px;
}

.input-wrapper:focus {
  outline: none;
  border: 2px solid var(--v-border-darken1);
}

input {
  @extend .text-body-1;
  color: var(--v-text-base);
  padding: 12px 16px 12px 12px;
  cursor: pointer;
}

.default-wrapper-style {
  border: 1px solid var(--v-border-base);
}
.default-input-style::placeholder {
  color: var(--v-text-lighten1);
}
.error-wrapper-style {
  border: 1px solid var(--v-quaternary-base);
}
.error-input-style::placeholder {
  color: var(--v-quaternary-base) !important;
}

input::placeholder {
  @extend .text-body-1;
  opacity: 1;
}

input:focus-visible {
  outline: none;
}

.v-menu__content {
  margin-top: 8px;
}

::v-deep .v-date-picker-table .v-date-picker-table__current {
  border: none;
  .v-btn__content {
    font-weight: 800 !important;
    color: var(--v-primary-base) !important;
  }
}

::v-deep .v-date-picker-table .v-btn .v-btn__content {
  font-family: 'Manrope' !important;
  font-size: 16px !important;
  font-weight: 600;
  color: var(--v-text-base);
}

::v-deep .v-date-picker-table .v-btn:hover {
  border-radius: 4px;
  background-color: var(--v-background-darken2);
}

::v-deep .v-date-picker-table .v-btn:before {
  background-color: transparent !important;
}

::v-deep .v-date-picker-table .v-btn--active {
  border-radius: 4px;
  background-color: var(--v-primary-base) !important;
  .v-btn__content {
    color: white !important;
  }
}
