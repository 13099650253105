.s-button {
  min-height: 45px;
}

.s-button-primary {
  @extend .s-button;
  background-color: var(--v-btnPrimary-base) !important;
  color: var(--v-btnPrimaryText-base) !important;

  &:hover:not(:disabled) {
    background-color: var(--v-btnPrimary-darken1) !important;
  }

  &:disabled {
  }
}

.s-button-secondary {
  @extend .s-button;
  color: var(--v-btnSecondary-base) !important;
  border-color: var(--v-btnSecondaryBorder-base) !important;

  &:hover:not(:disabled) {
    color: var(--v-btnSecondary-darken1) !important;
  }

  &:before {
    background-color: transparent !important;
  }

  &:disabled {
  }
}

.s-button-secondaryCancel {
  @extend .s-button;
  color: var(--v-secondaryCancel-base) !important;
  border-color: var(--v-btnSecondaryBorder-base) !important;

  &:hover:not(:disabled) {
    color: var(--v-secondaryCancel-darken1) !important;
  }

  &:before {
    background-color: transparent !important;
  }

  &:disabled {
  }
}

.s-button-tertiary {
  @extend .s-button;
  color: var(--v-btnTertiary-base) !important;
  border-color: var(--v-btnTertiaryBorder-base) !important;

  &:hover:not(:disabled) {
    color: var(--v-btnTertiary-darken1) !important;
    background-color: var(--v-tertiary-darken1) !important;
  }

  &:before {
    background-color: transparent !important;
  }

  &:disabled {
  }
}

.s-button-quaternary {
  @extend .s-button;
  color: var(--v-btnQuaternaryText-base) !important;
  background-color: var(--v-btnQuaternary-base) !important;
  border-color: var(--v-btnQuaternaryBorder-base) !important;

  &:hover:not(:disabled) {
    background-color: var(--v-btnQuaternary-darken1) !important;
    color: var(--v-btnQuaternaryText-base) !important;
  }

  &:before {
    background-color: transparent !important;
  }

  &:disabled {
  }
}
