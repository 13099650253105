<template>
  <v-autocomplete
    data-cy="sautocomplete-multiple-rating"
    :label="$t(placeholder)"
    :items="items"
    v-model="selectedItemComponentModel"
    @change="handleChange"
    item-value="id"
    item-text="desc"
    class="custom-v-autocomplete"
    :style="customStyle"
    :height="inputHeight"
    return-object
    @blur="handleBlur"
    @focus="handleFocus"
    :disabled="disabled"
    multiple
    dense
    flat
    solo
  >
    <template v-slot:selection="{ index }">
      <span
        v-if="!labelDisplaySelection && index === 0"
        class="pt-1 pb-1 text-body-1 text--text text--lighten-1"
        data-cy="sautocomplete-selection-label"
      >
        {{ labelDisplay }}
      </span>
      <span
        v-if="labelDisplaySelection && index === 0"
        class="pt-1 pb-1 text-body-1-medium text--text text--base"
        data-cy="sautocomplete-selection-label"
      >
        {{ labelDisplay }}
      </span>
    </template>
    <template v-slot:item="{ item }">
      <div
        :class="[
          'd-inline-flex',
          'align-center',
          'w-100',
          'h-100',
          { 'selected-item': isItemSelected(item) || showRating(item) },
          { 'font-weight-bold': isItemSelected(item) }
        ]"
        style="min-width: 100% !important; min-height: 100% !important"
        data-cy="sautocomplete-dropdown-item"
        @click.self="handleItemSelection(item)"
        @mouseover="handleMouseOver(item.id)"
        @mouseleave="handleMouseLeave"
      >
        <div
          :class="[
            'item-desc',
            'mr-2',
            'text--text',
            'text--base',
            { 'selected-item': isItemSelected(item) },
            { 'text-body-1': !isItemSelected(item) },
            { 'text-body-1-bold': isItemSelected(item) }
          ]"
          data-cy="sautocomplete-item-desc"
          @click.self="handleItemSelection(item)"
        >
          {{ item.desc }}
        </div>
        <div v-if="showRating(item)" class="text-center d-inline-flex align-center h-100" data-cy="sautocomplete-rating-container">
          <v-rating
            class="d-inline-flex align-center h-100"
            @input.self="handleRatingChange(item)"
            v-model="item.rating"
            length="3"
            clearable
            dense
            data-cy="sautocomplete-item-rating"
          >
            <template v-slot:item="props">
              <v-tooltip top content-class="background-darken3 tooltip-top" nudge-right="0">
                <template v-slot:activator="{ on, attrs }">
                  <v-img
                    :width="20"
                    :src="
                      '/content/svgs/star/' +
                      (props.isFilled
                        ? 'star-fill-blue'
                        : isStarHovered(item.id, props.index)
                        ? 'star-empty-blue'
                        : 'star-empty-grey-lighten1') +
                      '.svg'
                    "
                    v-bind="attrs"
                    v-on="on"
                    @click="props.click"
                    @mouseover="handleStarHover(item.id, props.index)"
                    @mouseleave="handleStarLeave(item.id)"
                    data-cy="sautocomplete-item-star"
                  ></v-img>
                </template>
                <div class="pt-1">
                  <div class="d-flex align-center">
                    <span class="pr-1 text-body-4-bold">
                      {{ getTitleBadge(currentTier) }}
                    </span>
                    <span v-for="star in currentTier" :key="star">
                      <v-img :width="20" src="/content/svgs/star/star-fill-white.svg"></v-img>
                    </span>
                  </div>
                  <p class="mb-2 text-body-3">
                    {{ getInfoBadge(currentTier, item.id) }}
                  </p>
                </div>
              </v-tooltip>
            </template>
          </v-rating>
        </div>
      </div>
    </template>
  </v-autocomplete>
</template>

<script lang="ts" src="./sAutocompleteMultipleRating.ts" />

<style scoped>
.custom-v-autocomplete >>> .v-input__slot {
  border: 1px solid var(--v-border-base) !important;
  border-radius: 4px;
  margin-bottom: 0px !important;
}

.v-input--is-focused >>> .v-input__slot {
  border-color: var(--v-border-darken1) !important;
}

.custom-v-autocomplete >>> .v-text-field__details {
  display: none;
}

.custom-v-autocomplete >>> .v-select__selections {
  flex-wrap: nowrap !important;
}

.custom-v-autocomplete >>> .v-select__selections span {
  display: inline-block;
  max-width: 100%;
  min-width: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
  letter-spacing: normal !important;
}

.custom-v-autocomplete >>> .v-select__slot label {
  color: var(--custom-color) !important;
}

.custom-v-autocomplete >>> .v-select__slot .v-input__append-inner {
  cursor: pointer;
}

.v-menu__content .v-list >>> .v-subheader {
  font-family: 'Manrope' !important;
  color: var(--v-text-lighten1);
  font-size: 16px !important;
  font-weight: 700 !important;
}

.v-menu__content .v-list >>> .v-list-item--active::before {
  background-color: transparent !important;
}

::v-deep .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: transparent !important;
}

.v-menu__content .v-list >>> .selected-item .item-desc {
  color: var(--v-primary-base) !important;
}

.v-tooltip__content {
  background-color: var(--v-background-darken3);
  opacity: 1;
  color: var(--v-text-lighten4);
  border-radius: 4px;
  max-width: 33%;
  min-width: 398px;
}

.tooltip-top::before {
  border-right: solid 8px transparent;
  border-left: solid 8px transparent;
  transform: translateX(-50%);
  position: absolute;
  z-index: -10;
  content: '';
  top: 100%;
  left: 50%;
  height: 0;
  width: 0;
}

.tooltip-top.background-darken3::before {
  border-top: solid 8px var(--v-background-darken3);
}
</style>
