
.checkout-footprint {
  display: flex;
  flex-direction: column;
  align-items: center;

  .title-span {
    color: var(--v-text-base);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-bottom: 28px;
  }
}

.checkout-chart {
  margin: 64px 0px 32px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 206px;

  .title-span {
    color: var(--v-text-base);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-bottom: 3px;
  }
}

.footprint-info {
  display: flex;
  padding: 5px;
  align-items: center;
  justify-content: center;
  transform: scaleY(-1);
}

.circle-container {
  width: 135px;
  height: 135px;
  padding: 0px 12px;
}

.footprint-info {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.footprint-card {
  display: flex;
  flex-direction: column;
  color: var(--v-text-base);
  font-style: normal;
  line-height: normal;

  .footprint-location {
    font-size: 14px;
    font-weight: 500;
  }

  .footprint-value {
    font-size: 20px;
    font-weight: 700;
    background: var(--Gradient, linear-gradient(86deg, #4237ff 8.09%, #9747ff 93.98%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .footprint-tco {
    color: var(--v-text-lighten1);
    font-size: 12px;
    font-weight: 400;
  }
}
