#notification-dropmenu {
}

.btn-noti {
  background: white;
  border-radius: 4px;
  border: 1px #dbdbe0 solid;
}

.btn-noti:hover {
  cursor: pointer;
  background-color: #f9f9f9;
}
