<template>
  <div class="py-2 px-5" data-cy="availability-switch-container">
    <v-switch
      class="custom-switch-availability mt-0 pt-0"
      v-model="value"
      color="primary"
      @change="handleChange"
      data-cy="availability-switch"
    >
      <template v-slot:label>
        <span class="text-subtitle-3 text--text text--base" data-cy="availability-switch-label">
          {{ $t('explore.more-filters.modal.filter.filter-label-availability') }}
        </span>
      </template>
    </v-switch>
  </div>
</template>

<script lang="ts" src="./switchAvailability.ts" />

<style scoped>
.custom-switch-availability {
  margin-bottom: 0 !important;
}

.custom-switch-availability >>> .v-input__slot {
  margin-bottom: 0 !important;
}

.custom-switch-availability >>> .v-input__slot label {
  margin-bottom: 0 !important;
}

.custom-switch-availability >>> .v-messages {
  display: none;
}
</style>
