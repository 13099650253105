
.input-number-component {
  display: flex;
  align-items: center;
  border: 1px var(--v-text-lighten2) solid;

  .input-display {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--v-text-base) !important;

    .input {
      text-align: center;
      outline: none;
    }
  }

  .icon {
    cursor: pointer;
    max-width: 18px;
  }
}

.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
