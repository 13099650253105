@import '@content/scss/variables';

#register {
  .input-form-stemdo {
    background-color: white;
    border: 1px solid $lightGreyStemdo;
    display: block;
    width: 100%;
    height: 45px;
    font-size: 16px;
    font-weight: 400;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: var(--v-text-base);
    background-clip: padding-box;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .select-form-stemdo {
    background-color: white;
    border: 1px solid $lightGreyStemdo;
    display: block;
    width: 100%;
    height: 45px;
    font-size: 16px;
    font-weight: 400;
    padding: 0.375rem 0px;
    line-height: 1.5;
    color: var(--v-text-base);
    background-clip: padding-box;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .input-form-stemdo:focus {
    color: var(--v-text-base);
    outline: 1px solid rgba($color: $greyStemdo, $alpha: 1);
    &::placeholder {
      color: $greyStemdo;
    }
  }

  .input-error {
    color: $redStemdo;
    outline: 1px solid rgba($color: $redStemdo, $alpha: 1);
    position: relative;
  }

  .validation-text {
    margin-bottom: 10px;
  }

  .validation-text-container {
    margin-top: 6px;
    span {
      color: $greyStemdo;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-right: 19px;
    }

    .valid-password {
      color: $greenStemdo;
    }
    .invalid-password {
      color: $redStemdo;
    }
  }

  .span-error {
    padding-top: 0.5em;
    color: $redStemdo;
    font-size: 14px;
    font-weight: 800;
  }

  .input-error:focus {
    outline: 1px solid rgba($color: $greyStemdo, $alpha: 1);
  }

  ::-webkit-input-placeholder {
    color: $greyStemdo;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    --tw-bg-opacity: 1;
    -webkit-box-shadow: 0 0 0 40px rgba($greyStemdo, var(--tw-bg-opacity)) inset !important;
  }

  .input-form-group-stemdo {
    border: 0px solid $lightGreyStemdo;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
    border-radius: 0.25rem;
  }

  .form-group {
    label {
      margin-top: 14px;
      padding: 10px 0px;
      margin-bottom: 0px;
    }
  }

  .form-title {
    max-width: 330px;
    h1,
    h2 {
      color: var(--v-text-base);
      margin-bottom: 8%;
    }
    p {
      margin-bottom: 4%;
      font-size: 16px;
    }
  }

  .login-container {
    display: flex;
    margin: 0;
    justify-content: space-between;
    min-height: fit-content;
  }

  .login-image {
    width: 100vw;
    min-height: 100vh;
    background-color: $lightGreyStemdo;
    display: flex;
    align-items: flex-end;
  }

  .register-image {
    margin-left: 48px;
    margin-bottom: 142px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 75%;

    .register-title {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      h1 {
        font-size: 50px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        color: var(--v-text-base);
        margin-bottom: 11%;
      }
    }

    .image-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      max-width: 560px;
      width: 100%;
      flex-wrap: wrap;
      align-items: center;

      img {
        margin-right: 50px;
        padding: 16px 20px 14.07px 0px;
        min-width: 115px;
        max-width: 130px;
      }
    }
  }

  .login-form {
    position: relative;
    padding: 5px;
    border-radius: 30px 0px 0px 30px;
    background-color: $whiteStemdo;
    width: 56.8%;
    margin-left: -30px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
    padding-bottom: 36px;

    .form-text {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      color: var(--v-text-base);
      position: absolute;
      top: 0;
      right: 0;
      padding: 2.2em 3.5em 1em 2.5em;
      height: 15%;
    }
  }

  .SForm {
    width: 55%;
    margin-top: 48px;
  }

  .login-span {
    color: $darkGreyStemdo;
    font-weight: 600;
  }
  .login-checkbox {
    margin: 32px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .login-checkbox input[type='checkbox'] {
    margin: 5px;
  }

  .login-checkbox label {
    margin: 0;
    padding: 0;
    color: var(--v-text-base);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .separator {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid $lightGreyStemdo;
    line-height: 0.1em;
    margin: 5% 0;
  }

  .login-error {
    display: flex;
    flex-direction: column;

    a {
      text-decoration-line: underline;
    }
  }

  .name-input {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .circle-step {
    min-width: 24px;
    min-height: 24px;
    max-width: 24px;
    max-height: 24px;
    background: white;
    border-radius: 50%;
    border: 1px solid var(--v-text-base) !important;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      margin-top: 0.1rem;
    }
  }
  .circle-step-green {
    @extend .circle-step;
    border-color: var(--v-tertiary-base) !important;
  }
}
