
.main-box {
  border: 1px solid #dbdbe074;
  border-radius: 3px;
  min-width: 100%;
}

.theme--light.v-chip:not(.v-chip--active) {
  color: var(--v-text-lighten1) !important;
  background-color: var(--v-background-darken2) !important;
}

.v-tooltip__content {
  background-color: var(--v-background-darken3);
  color: var(--v-text-lighten4);
}
