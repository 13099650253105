
.main-box {
  border: 1px solid #dbdbe074;
  border-radius: 3px;
  width: 100%;
}
.header-content {
  color: #222226 !important;
  font-size: 20px;
  font-family: Manrope;
  font-weight: 800 !important;
  word-wrap: break-word;
  width: fit-content;
}
.grade {
  color: #222226;
  font-size: 16px;
  font-family: Manrope;
  font-weight: 800;
  word-wrap: break-word;
}
