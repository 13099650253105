
@import './sNotification.scss';

::v-deep .v-list-group__header {
  border: 1px solid var(--v-border-darken1) !important;
  border-radius: 4px;
  height: 62px;
  width: 100%;
}

::v-deep .v-list-group__items {
  margin-top: 4px;
  background: white;
  box-shadow: 0px 2px 8px 1px var(--v-border-darken1);
  border-radius: 4px;
  position: absolute;
  z-index: 1000;
  width: -webkit-fill-available;
  cursor: default;
}

::v-deep .v-list-group__items .v-list-item:not(:last-child) {
  border-bottom: 1px var(--v-border-darken1) solid !important;
}

::v-deep .v-list-group__items:not(:empty) {
  border: 1px var(--v-border-darken1) solid !important;
}

::v-deep .v-list-item {
  z-index: 1000;
  padding: 0px 11px !important;
}
