
#candidates-filter {
  background: var(--White, #fff);
  min-width: 100%;
  min-height: 100%;
}

.filter-container {
  background: var(--v-background-darken1);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border: 1px solid var(--v-border-base) !important;
}

.custom-v-autocomplete ::v-deep .v-input__slot {
  border: 1px solid var(--v-border-base) !important;
  border-radius: 4px;
  height: 45px;
  color: var(--v-text-base) !important;
  margin-bottom: 0px !important;
  background: var(--v-background-base) !important;
}

::v-deep input::placeholder {
  color: var(--v-text-lighten1) !important;
}

.custom-v-search ::v-deep .v-input__slot {
  background-color: var(--v-background-base) !important;
  border: 1px solid var(--v-background-darken2) !important;
  border-radius: 25px;
  height: 45px;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}

.custom-text-field ::v-deep input::placeholder,
.custom-text-field ::v-deep .v-label,
.custom-text-field ::v-deep .v-icon {
  color: var(--v-text-lighten2) !important;
}

.v-input--is-focused ::v-deep .v-input__slot {
  border-color: var(--v-border-darken1) !important;
}

.custom-v-autocomplete ::v-deep .v-text-field__details {
  display: none;
}

.custom-v-autocomplete ::v-deep .v-select__selections {
  flex-wrap: nowrap !important;
}

.custom-v-autocomplete ::v-deep .v-select__selections span {
  display: inline-block;
  max-width: 100%;
  min-width: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
  letter-spacing: normal !important;
}

.custom-v-autocomplete ::v-deep .v-list-item__title .v-list-item__content {
  font-size: 30px;
}
