#stemdoerListSkeleton {
  min-width: 100%;
}

#stemdoer-card-skeleton {
  height: 16em;
  width: 100%;
  border-radius: 4px;
  border: 1px solid var(--v-background-darken2);
  box-shadow: 0px 1px 0px 0px var(--v-background-darken1);
}

.skeleton-button {
  padding: 4px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}
