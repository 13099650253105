
@import '@content/scss/vuetify.scss';

textarea {
  @extend .text-body-1;
  color: var(--v-text-base);
  padding: 12px 16px 12px 16px;
  border-radius: 4px;
}

.default-style {
  border: 1px solid var(--v-border-base);
}
.default-style::placeholder {
  color: var(--v-text-lighten2);
}
.error-style {
  border: 1px solid var(--v-quaternary-base);
}
.error-style::placeholder {
  color: var(--v-quaternary-base) !important;
}

textarea:focus {
  outline: none;
  border: 2px solid var(--v-border-darken1);
}

textarea::placeholder {
  @extend .text-body-1;
  opacity: 1;
}
