
@import './register.scss';

::v-deep .terms-link-accepted {
  .terms {
    color: $blackStemdo;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  a {
    color: $blueStemdo !important;
    text-decoration-line: underline !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}

.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep .terms-link-not-accepted {
  .terms {
    color: $redStemdo !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  a {
    color: $redStemdo !important;
    text-decoration-line: underline !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}

::v-deep .v-input__slot {
  padding: 0px;
}

::v-deep .v-select__selections {
  padding: 0;
}

.custom-v-select >>> .v-input__slot {
  border-radius: 4px;
  margin-bottom: 0px !important;
}

.v-input--is-focused >>> .v-input__slot {
  border-color: var(--v-border-darken1) !important;
}

.custom-v-select >>> .v-text-field__details {
  display: none;
}

.custom-v-select >>> .v-select__selections {
  flex-wrap: nowrap !important;
}

.custom-v-select >>> .v-select__selections span {
  display: inline-block;
  max-width: 100%;
  min-width: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
  letter-spacing: normal !important;
}

.custom-v-select >>> .v-select__slot label {
  color: var(--custom-color) !important;
}

.custom-v-select >>> .v-select__slot .v-input__append-inner {
  cursor: pointer;
}

.v-menu__content .v-list >>> .v-list-item--active::before {
  background-color: transparent !important;
}

.v-menu__content .v-list >>> .selected-item {
  color: var(--v-primary-base) !important;
}
