#read-more {
  .description {
    color: #606061;
    font-size: 16px;
    font-family: Manrope;
    font-weight: 400;
    word-wrap: break-word;
  }

  a {
    color: #606061;
    font-weight: 800;
    font-size: 16px;
    font-family: Manrope;
  }
}
