#candidates-wrapper {
  border-radius: var(--border-radius, 4px);
  background: var(--White, #fff);
  min-width: 100%;
  min-height: 100%;

  .title-page {
    color: var(--v-text-primary, #222226) !important;
  }

  .assigned-candidates {
    border: 1px solid var(--v-border-light, #ececef) !important;
    border-radius: 4px;
    height: 62px;
    width: 441px;
    min-width: 330px;
    margin-left: 24px;

    .assigned-info {
      color: var(--v-text-primary, #222226) !important;
      min-width: 85%;
    }

    .settings {
      max-width: 24px;
      max-height: 24px;
      border: 1px solid var(--v-border-dark, #dbdbe0) !important;
      border-radius: 4px;
      margin-left: auto;
      cursor: pointer;
    }
  }

  .candidates-tip {
    span {
      color: var(--v-text-secondary, #606061) !important;
      max-width: 100%;
    }
  }
}
