
.close-button {
  cursor: pointer;
  border: 1px solid var(--v-border-darken1);
  border-radius: 50%;
  padding: 1px;
}

.close-button:hover {
  background-color: var(--v-background-darken1);
}

.detail-link {
  display: flex;
  align-items: center;
}

::v-deep .modal-span {
  color: var(--v-text-lighten1) !important;
  word-wrap: break-word;
}

::v-deep .input-form-stemdo {
  font-size: 16px;
  background-color: #ffffff;
  border: 1px solid var(--v-border-base) !important;
  display: block;
  width: 100%;
  height: 45px;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: var(--v-background-darken3) !important;
  background-clip: padding-box;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &::placeholder {
    color: var(--v-text-lighten2) !important;
  }
}

::v-deep .input-form-stemdo:focus {
  color: var(--v-background-darken3) !important;
  outline: 1px solid rgba($color: #dbdbe0, $alpha: 1);
}

::v-deep .v-input__slot {
  border: 1px solid var(--v-text-lighten3);
  border-radius: 4px;
  flex-shrink: 0;
  margin: 10px 0px 24px 0px;
}

::v-deep .v-text-field .v-input__slot {
  cursor: pointer;
  background-color: #ffffff !important;
  border: 1px solid var(--v-text-lighten3) !important;
  border-color: var(--v-text-lighten3) !important;
  min-height: 45px !important;

  input {
    cursor: pointer;
  }
}
