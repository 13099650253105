.popUp {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  margin: -32px 0px 0px 0px;
  width: fit-content;
  min-width: 38%;
}

.no-available-stemdoers-banner {
  background-color: var(--v-quinary-lighten1);
  margin: 32px 0px 0px 0px;
  padding: 20px 24px 20px 24px;
  border-radius: 4px;
}

.no-available-stemdoers-banner-go-explore {
  color: #4237ff !important;
  cursor: pointer;
}
