.bg-primary {
  background-color: #151515 !important;
  // background-color: #fbff00 !important;
  box-shadow: 1px 1px 7px rgba(75, 75, 75, 0.712);
}

.notiBell {
  position: relative;
  z-index: 1;
}

.notiBall {
  display: inline-block;
  position: absolute;
  top: -12px;
  right: -9px;
  background-color: #ff0000;
  border-radius: 50%;
  padding: 5px;
  color: #fff;
  z-index: 2;
  transform: scale(0.3);
}

nav li .router-link-active .navbar-dropdown-menu {
  color: red !important;
}

.nav-item {
  a:hover,
  a:focus:hover {
    color: #00c2b1 !important;
    // font-weight: bold;
  }

  a:focus {
    color: rgba(255, 255, 255, 0.6) !important;
    // font-weight: bold;
  }

  .dropdown-item:hover {
    color: white !important;
  }

  .dropdown-item.active {
    background-color: #151515 !important;
    color: #00c2b1 !important;
  }
}

.navbar-version {
  font-size: 10px;
}

@media screen and (min-width: 768px) {
  .jh-navbar-toggler {
    display: none;
  }
}

@media screen and (min-width: 768px) and (max-width: 1150px) {
  span span {
    display: none;
  }
}

.navbar-title {
  display: inline-block;
  vertical-align: middle;
}

/* ==========================================================================
    Logo styles
    ========================================================================== */
.navbar-brand.logo {
  padding: 5px 15px;
}

.logo .logo-img {
  height: 45px;
  display: inline-block;
  vertical-align: middle;
  width: 70px;
}

.logo-img {
  height: 100%;
  background: url('../images/emini.png') no-repeat center center;
  background-size: contain;
  transform: scale(1.4);
  width: 100%;
  filter: drop-shadow(0 0 0.05rem white);
}

#noti-id .dropdown-item:hover {
  background-color: #00c2b1;
  font-weight: 800;
}
#noti-id .dropdown-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 10px 0;
  height: auto;
  padding: 0.5em;
  sub {
    margin-bottom: 0.7em;
  }
}

#noti-id .dropdown-item sub {
  margin-top: -1em;
}
