#request-list {
  .team-request-table {
    box-shadow: none !important;
  }

  .delete-trash {
    background: url('../../../../../content/svgs/trash.svg') no-repeat center center;
    background-size: cover;
    width: 24px !important;
    height: 24px !important;
    transition: 0.3s;
  }

  .delete-trash:hover {
    background: url('../../../../../content/svgs/trash-red.svg') no-repeat center center;
    background-size: cover;
    transition: 0.3s;
  }
}

.chip-container {
  display: flex;
  flex-direction: row;
}

.overlapping-chip {
  background-color: var(--v-background-darken3) !important;
  color: white !important;
  margin-right: -8px;
  padding: 0 !important;
  font-family: 'Manrope' !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  width: 30px !important;
  height: 30px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar {
  width: 100% !important;
  height: 100% !important;
  border-radius: 50%;
  object-fit: cover !important;
  background-color: var(--v-background-darken3) !important;
  border: white solid 1px;
}

.name-cell {
  font-weight: 600 !important;
}

.status-container {
  display: flex;
  align-items: center;

  span {
    border: 1px solid var(--v-text-lighten3);
    background-color: var(--v-text-lighten4);
    border-radius: 50px;
    white-space: nowrap;
  }
}

.list-option {
  font-weight: 400;
  cursor: pointer;
  user-select: none;
}

.list-option:hover {
  color: #4237ff;
}

.menu-right {
  right: 0;
}
