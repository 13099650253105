.stemdoBtn {
  margin-top: 2em;
  transition: 0.5s;
  cursor: pointer;
  display: flex;
  background-color: #ffffff;
  color: rgb(17, 17, 17);
  border-radius: 8px;
  padding: 0.46em 1em 0.46em 1em;
  transition: 0.5s;
  box-shadow: 1px 1px 4px #6d57bb65, -1px -1px 6px #8680da75;
  text-decoration: none;

  a {
    text-decoration: underline none;
  }

  span {
    text-decoration: underline none;
    padding-right: 0.3em;
  }

  svg {
    padding: 0.5em;
    transform: rotate(45deg);
    color: $whiteStemdo;

    margin-bottom: -0.05em;
    transition: 0.4s;
    padding-left: 0.2em;
  }
}

.stemdoBtn:hover {
  transition: 0.5s;
  color: #222020;
  background: #f3f3f3;
  box-shadow: 2px 2px 4px #6d57bb65, -2px -2px 6px #8680da75;

  a {
    text-decoration: underline none;
  }

  span {
    text-decoration: underline none;
  }

  svg {
    transition: 0.4s;
    transform: rotate(0deg);
    transform: translateY(-2px);
  }
}

.svgContainer {
  display: inline-block;
  border-radius: 4px;
  padding-left: 0.03em;
  text-align: center;
  width: 1.7em;
  height: 1.6em;
  background-color: $blueStemdo;

  svg {
    stroke-width: 10px;
  }
}
.gsignContent {
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
  margin-top: 1em;
}
.gsign {
  width: 100%;
  border-radius: var(--border-radius, 4px);
  border: 1px solid var(--Colors-Light-grey, $lightGreyStemdo);
  background: var(--Background, #fff);
  text-align: left;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  padding: 0.5em 1em;
  font-weight: 400;
  color: $blackStemdo;
  box-shadow: 0px 4px 4px -3px rgba(0, 0, 0, 0.1);
}
