.footer {
  // box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.727);
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
  background-color: #151515 !important;
  color: #ada7a7;
  // border-top: 2px solid rgba(255, 255, 255, 0.541);
}

#footer {
  margin-top: 1em !important;
  width: 100%;
  height: 4em;
  position: absolute;
  bottom: 0;
  left: 0;
}

.stemdoDataFooter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
