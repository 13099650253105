
@import './teamRequestList.scss';

::v-deep .v-data-table__wrapper tr:hover {
  background-color: #f9f9f9 !important;
}

::v-deep .v-data-table__wrapper table {
  border-collapse: separate !important;
}

::v-deep .v-data-table__wrapper table thead {
  th {
    background-color: var(--v-background-darken1) !important;
    border-bottom: none !important;
    border-top: 1px solid var(--v-border-base) !important;
    color: var(--v-text-lighten1) !important;
    font-family: 'Manrope' !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
    margin-bottom: 10px !important;
    height: 44px !important;
  }

  th:first-child {
    border-left: 1px solid var(--v-border-base) !important;
  }

  th:last-child {
    border-right: 1px solid var(--v-border-base) !important;
    border-radius: 0 4px 0 0;
  }
}

::v-deep .v-data-table__wrapper table tbody {
  cursor: pointer !important;

  td {
    font-size: 16px !important;
    border-bottom: 1px solid var(--v-border-base) !important;

    &:first-child {
      border-left: 1px solid var(--v-border-base) !important;
    }

    &:last-child {
      border-right: 1px solid var(--v-border-base) !important;
    }
  }

  tr:first-of-type {
    td {
      border-top: 1px solid var(--v-border-base) !important;
    }
  }

  tr:last-child {
    td {
      border-bottom: 1px solid var(--v-border-base) !important;
    }

    td:first-child {
      border-bottom-left-radius: 4px;
    }

    td:last-child {
      border-bottom-right-radius: 4px;
    }
  }
}

::v-deep .remove-box-shadow {
  box-shadow: none !important;
}

::v-deep .overlay-text {
  font-size: 12px !important;
  font-weight: 500 !important;
  color: white !important;
}

::v-deep #StemdoerAvatarImgFrame {
  cursor: pointer;
  background-color: var(--v-text-base) !important;
  width: 30px !important;
  height: 30px !important;
  border: 1px solid white !important;
}
