
::v-deep .v-data-table__wrapper table {
  border-collapse: separate !important;
}

::v-deep .v-data-table__wrapper table thead {
  th {
    background-color: var(--v-background-darken1) !important;
    border-bottom: none !important;
    color: var(--v-text-lighten1) !important;
    margin-bottom: 10px !important;
    height: 44px !important;
  }

  th:first-child {
    border-radius: 4px 0 0 4px;
  }

  th:last-child {
    border-radius: 0 4px 4px 0;
  }
}

::v-deep .v-data-table__wrapper table tbody {
  td {
    font-size: 16px !important;
    border-bottom: 1px solid var(--v-border-base) !important;

    &:first-child {
      border-left: 1px solid var(--v-border-base) !important;
    }

    &:last-child {
      border-right: 1px solid var(--v-border-base) !important;
    }
  }

  tr:first-of-type {
    td {
      border-top: 1px solid var(--v-border-base) !important;
    }

    td:first-child {
      border-top-left-radius: 4px;
    }

    td:last-child {
      border-top-right-radius: 4px;
    }
  }

  tr:last-child {
    td {
      border-bottom: 1px solid var(--v-border-base) !important;
    }

    td:first-child {
      border-bottom-left-radius: 4px;
    }

    td:last-child {
      border-bottom-right-radius: 4px;
    }
  }
}

::v-deep .remove-box-shadow {
  box-shadow: none !important;
}

::v-deep .v-skeleton-loader__text {
  background-color: var(--v-background-darken1) !important;
  height: 24px !important;
  margin: 8px !important;
}

::v-deep .v-skeleton-loader__bone::after {
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.65) 50%,
    rgba(255, 255, 255, 0) 100%
  ) !important;
}
