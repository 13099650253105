
@import './filterCards.scss';

::v-deep .v-btn {
  text-transform: unset !important;
}

.auxiliary-buttons {
  width: 100%;
  height: 79px;
  border: 1px solid var(--v-border-base);
  display: flex;
  align-items: center;
  background-color: var(--v-background-darken1) !important;
  margin-bottom: -2px;
  border-top-right-radius: 4px;
  z-index: 0;
}
