.top-filter-wrapper {
  border: 1px solid var(--v-border-darken1);
  border-radius: 4px;
  box-shadow: 0px 1px 0px 0px var(--v-border-darken1);
  min-width: 100%;

  .filters-tags {
    border-top: 1px solid var(--v-border-darken1);
  }
}
