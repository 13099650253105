
.d-flex {
  display: flex;
}

.flex-grow-1 {
  flex-grow: 1;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.nowrap {
  flex-wrap: nowrap;
}

.align-items-center {
  align-items: center;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.pb-2 {
  padding-bottom: 0.5rem;
}

.btnPDF {
  background-color: rgba(255, 255, 255, 0) !important;
  width: auto !important;
  height: auto !important;
  flex-shrink: 0;
  margin-left: 6px;
  box-shadow: none !important;
  border-radius: 4px;
  border: 1px #dbdbe0 solid;

  .iconPDF {
    transform: scale(0.9);
  }
}

.btnPDF:hover {
  background-color: #f9f9f9 !important;
}

::v-deep .v-text-field__details {
  display: none;
}

::v-deep .v-text-field__slot {
  label {
    color: #606061 !important;
    font-size: 16px !important;
    font-family: Manrope !important;
    font-weight: 400 !important;
    word-wrap: break-word !important;
  }
}

::v-deep .v-input__slot {
  background-color: #f9f9f9 !important;
  padding-bottom: 12px !important;
  padding-top: 0px !important;

  fieldset {
    border: #ececef 1px solid !important;
  }
}

.title-text-link {
  color: #606061 !important;
  font-size: 14px !important;
  font-family: Manrope;
  font-weight: 700 !important;
  word-wrap: break-word;
}

.text-field-link {
  color: #606061 !important;
  font-size: 16px !important;
  font-family: Manrope;
  font-weight: 400 !important;
  word-wrap: break-word;
}

.btn-copiar {
  color: #4237ff !important;
  font-size: 16px !important;
  font-family: Manrope;
  font-weight: 700 !important;
  word-wrap: break-word;
}

.btn-pdf {
  color: #4237ff !important;
  font-size: 16px !important;
  font-family: Manrope;
  font-weight: 700 !important;
  word-wrap: break-word;
}

.link-abrir-perfil {
  color: #4237ff !important;
  font-size: 12px !important;
  font-family: Manrope;
  font-weight: 400 !important;
  text-decoration: underline !important;
  word-wrap: break-word;
}
